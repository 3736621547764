<template >
  <div>
    <div v-if="!pre_loading" class="pre-loading">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>
    <v-form v-else @submit.prevent="onSubmit" ref="createForm">

      <v-row dense class="pt-5">
        <v-col :cols="2">
          <v-autocomplete name="operadora" outlined label="Operadora" v-model="selectedRow.operadora_id" return-object dense
            :items="operadoras" item-value="id" item-text="nome" required
            :rules="[(e) => e !== undefined || 'Obrigatório']" @change="getChildrensOperadoras" readonly
            disabled></v-autocomplete>
        </v-col>
        <v-col :cols="3">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-sheet  v-on="on"  v-bind="attrs" @click="openEditPage" style="cursor: pointer; padding: 0;" >
                <v-text-field outlined v-model="selectedRow.paciente" dense :loading="loading_pac" label="Paciente" readonly style="pointer-events: none;" >
                </v-text-field>
              </v-sheet>
            </template>
            <span>Clique para Editar o Cadastro</span>
          </v-tooltip>
        </v-col>
        <v-col>
          <v-text-field v-model="selectedRow.paciente_o.id" name="paciente_id" readonly outlined disabled dense
            label="ID do Paciente" ></v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="data_nascimento" readonly outlined label="Data de Nascimento" required disabled dense
            v-model="selectedRow.data_nascimento" :rules="[(e) => e !== undefined || 'Obrigatório']"
            type="date"></v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-autocomplete name="modalidade" outlined label="Modalidade" v-model="selectedRow.modalidade_o" dense
          :items="modalidades" item-value="id" item-text="nome" required :rules="requiredField"
          disabled></v-autocomplete>
        </v-col>
        <v-col :cols="2">
          <v-text-field name="nr_carteirinha" readonly outlined label="Nr. Carteirinha"  disabled dense
            v-model="selectedRow.nr_carteirinha" ></v-text-field>
        </v-col>

      </v-row>
      <v-row dense>
        <v-col :cols="3">
          <v-text-field name="data_inicio" outlined label="Data de Início - Orçamento" v-model="selectedRow.data_inicio" dense
            type="date" :rules="requiredField" clearable>
          </v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field name="data_fim" outlined label="Data de Término - Orçamento" v-model="selectedRow.data_fim" dense
            type="date" clearable :rules="requiredField"></v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field  outlined disabled label="QTD Dias Orçados" :value="calculatedDays" dense></v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field name="nr_guia_prestador" outlined label="Nrº Guia do Prestador" dense type="Number"
            v-model="selectedRow.nr_guia_prestador" clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col :cols="3">
          <v-text-field name="senha" outlined label="Senha" v-model="selectedRow.senha"  dense clearable type="Number"></v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field name="nr_guia_operador" outlined label="Nrº Guia da Operadora" dense type="Number"
            v-model="selectedRow.nr_guia_operador" clearable></v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field name="senha_data_inicio" outlined label="Data Início - Validade da Senha" dense
            v-model="selectedRow.senha_data_inicio" type="date" clearable></v-text-field>
        </v-col>
        <v-col :cols="3">
          <v-text-field name="senha_data_fim" outlined label="Data Fim - Validade da Senha" dense
            v-model="selectedRow.senha_data_validade" type="date" clearable></v-text-field>
        </v-col>
      </v-row>
      <v-row dense class="relative">
        <v-col cols="4" class="d-flex align-center" >
          <p class="label mb-0 mr-2">Status do Orçamento => </p>
          <v-btn-toggle v-model="selectedRow.ativo" mandatory dense >
            <v-btn :value="false" :class="{ purple: !selectedRow.ativo, 'white--text': !selectedRow.ativo }" >
              Em análise
            </v-btn>
            <v-btn :value="true" :class="{ green: selectedRow.ativo, 'white--text': selectedRow.ativo }">
              Aprovado
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="4" class="d-flex align-center" v-if="!selectedRow.ativo">
          <p class="label mb-0 mr-2">Orçamento Reprovado => </p>
          <v-btn-toggle v-model="selectedRow.reprovado" mandatory dense >
            <v-btn :value="false" :class="{ red: !selectedRow.reprovado, 'white--text': !selectedRow.reprovado }">
              NÃO
            </v-btn>
            <v-btn :value="true" :class="{ green: selectedRow.reprovado, 'white--text': selectedRow.reprovado }">
              SIM
            </v-btn>
          </v-btn-toggle>
        </v-col>
        <v-col cols="4" class="d-flex align-center" v-if="selectedRow.ativo">
          <p class="label mb-0 mr-2">Suspender Atendimento => </p>
          <v-btn-toggle v-model="selectedRow.suspender_atendimento" mandatory dense >
            <v-btn :value="false"
              :class="{ red: !selectedRow.suspender_atendimento, 'white--text': !selectedRow.suspender_atendimento }">
              NÃO
            </v-btn>
            <v-btn :value="true"
              :class="{ green: selectedRow.suspender_atendimento, 'white--text': selectedRow.suspender_atendimento }">
              SIM
            </v-btn>
          </v-btn-toggle>
        </v-col>
      <!-- </v-row>
      <p></p>
      <v-row dense class="justify-end gap-4"> -->
        <template v-if="!selectedRow.manutencao">
          <v-col class="text-end mt-1" dense>
            <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree" @click="refreshPage('orcamento')" >
              Atualizar Tela </v-btn>
          </v-col>
          <v-tooltip
            v-if="selectedRow.ativo || evolucoes.length > 0 || taxas.length > 0 || equipamentos.length > 0 || selectedRow.reprovado"
            top>
            <template v-slot:activator="{ on, attrs }">
              <span v-bind="attrs" v-on="on">
                <ConfirmButton :disabled="true" :loading="isLoading('delete:sessao')" :block="false" class="mt-2 mr-2"
                  type="button" :onConfirm="handleDeleteSession" outlined color="error">
                  Excluir
                </ConfirmButton>
              </span>
            </template>
            <span>Para habilitar o botão, primeiro exclua todas os procedimentos, taxas e equipamentos associados.</span>
          </v-tooltip>
          <ConfirmButton v-else :disabled="false" :loading="isLoading('delete:sessao')" :block="false" class="mt-2 mr-2"
            type="button" :onConfirm="handleDeleteSession" outlined color="error">
            Excluir
          </ConfirmButton>
          <ConfirmButton :loading="isLoading('put:sessao')" :block="false" class="mt-2 mr-2 " outlined
            :onConfirm="onSubmit" color="success">
            Salvar
          </ConfirmButton>
        </template>
        <template v-else>
          <span class="text-h6 font-weight-bold text-center red--text my-2">Orçamento em Processo de Faturamento</span>
        </template>
        <!-- Relatórios -->
        <div class="align-self-bottom py-2 ml-5">
          <v-menu eager offset-x left offset-y bottom v-model="menu">
            <template v-slot:activator="{ on, attrs }">
              <v-icon class="align-center" color="green" size="30" v-bind="attrs" v-on="on">
                mdi-printer
              </v-icon>
            </template>
            <v-container class="container">
              <Orcamento :selectedBudgets="[selectedRow.id]"></Orcamento>
              <div v-if="isLoadingEvolucoes" class="com-evolucoes">Carregando Aditivos...</div>
              <div v-else-if="maxNrAditivo === null" class="sem-evolucoes">Sem Aditivos Gerados</div>
              <div v-else>
                <div v-for="aditivo in uniqueAditivos" :key="aditivo">
                  <AditivoIndividual :selectedBudgets="selectedRow.id" :nr-aditivo="aditivo"></AditivoIndividual>
                </div>
              </div>
            </v-container>
          </v-menu>
        </div>
      </v-row>
       <v-row>
        <v-col cols="4" v-if="selectedRow.reprovado">
          <v-autocomplete name="motivoreprovacao" outlined v-model="selectedRow.motivoreprovacao_o" dense
            :items="motivoreprovacao" item-value="id" item-text="nome" :rules="requiredField">
            <template v-slot:label>
              <span>Motivo da Reprovação Orçamento <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" v-if="selectedRow.suspender_atendimento && selectedRow.ativo">
          <v-text-field name="data_suspender_atendimento" outlined v-model="selectedRow.data_suspender_atendimento" dense
            type="date" :rules="requiredField" clearable>
            <template v-slot:label>
              <span>Data da Suspensão dos Atendimentos <span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="4" v-if="selectedRow.suspender_atendimento && selectedRow.ativo" dense>
          <v-autocomplete name="motivo_suspensao_atendimento" outlined
            v-model="selectedRow.motivo_suspensao_atendimento_o" :items="motivo_suspensao_atendimento" item-value="id" dense
            item-text="nome" :rules="requiredField">
            <template v-slot:label>
              <span>Motivo da Suspensão dos Atendimentos <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
        <v-col cols="4" v-if="selectedRow.suspender_atendimento && selectedRow.ativo" dense>
          <v-autocomplete name="hospital" outlined v-model="selectedRow.hospital_o" :items="hospital" item-value="id" dense
            item-text="nome" :rules="requiredField">
            <template v-slot:label>
              <span>Hospital ou Instituição <span style="color: red;">*</span></span>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
    </v-form>
    <v-divider class="mt-8"></v-divider>
    <v-tabs v-model="currentTab" class="mb-4">
      <v-tab>Resumo Geral</v-tab>
      <v-tab>Resumo Procedimento</v-tab>
      <v-tab>Procedimentos</v-tab>
      <v-tab>Mat./Med./Dieta</v-tab>
      <v-tab>Taxa</v-tab>
      <v-tab>Equipamento</v-tab>
      <v-tab>Pacote</v-tab>
      <v-tab>Autorizações</v-tab>
    </v-tabs>
    <!-- Abas -->
    <v-tabs-items v-model="currentTab">
      <!-- Resumo Geral -->
      <v-tab-item>
        <div dense class="mb-5">
          <v-row dense class="mt-4">
            <v-col v-if="isLoading('get:evolucoes') || isLoading('get:budget-details')">
              <div class="pre-loading d-flex justify-center">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:evolucoes') && !isLoading('get:budget-details')">
            <v-col cols="6" class=" d-flex justify-center">
              <h6 class="text-h6 blod_color"> Resumo </h6>
            </v-col>
            <v-col cols="6" class=" d-flex justify-center">
              <h6 class="text-h6 blod_color"> Resumo Ajustado (Desconto / Acréscimo)</h6>
            </v-col>
          </v-row>
          <!-- linha do Pacote -->
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:pacote') && !isLoading('get:budget-details')">
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">
                Valor do Pacote -
                <span v-if="pacote"> " {{ pacote.pacote.nome }} - </span>
                <span v-if="pacote"> {{ pacote.quantidade_pacote }} diária(s) ": </span>
                <span v-if="pacotes.length > 0"> {{ valordoPacote }} </span>
                <span v-else> R$ 0,00 </span>
              </h6>
            </v-col>
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">
                Valor do Pacote Ajustado -
                <span v-if="pacote"> " {{ pacote.pacote.nome }} - </span>
                <span v-if="pacote"> {{ pacote.quantidade_pacote }} diária(s) ": </span>
                <span v-if="pacotes.length > 0"> {{ valordoPacoteFinal }} </span>
                <span v-else> R$ 0,00 </span>
              </h6>
            </v-col>
          </v-row>
          <!-- Linha dos Procedimentos -->
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:evolucoes') && !isLoading('get:budget-details')">
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor dos Procedimentos: {{ valordosProcedimentos }}
              </h6>
            </v-col>
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor dos Procedimentos Ajustados: {{ valordosProcedimentosAjustado }}
              </h6>
            </v-col>
          </v-row>
          <!-- Linha dos Diária -->
            <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:evolucoes') && !isLoading('get:budget-details')">
              <v-col cols="6" class=" justify-start">
                <h4 class="text-h6 blod_color">Valor das Diárias: {{ valordosDiarias }}
                </h4>
              </v-col>
              <v-col cols="6" class=" justify-start">
                <h4 class="text-h6 blod_color">Valor das Diárias Ajustadas: {{ valordosDiariasAjustado }}
                </h4>
              </v-col>
            </v-row>
          <!-- Linha da Taxas -->
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:taxas') && !isLoading('get:budget-details')">
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor das Taxas: {{ valordastaxas }}
              </h6>
            </v-col>
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor das Taxas Ajustadas: {{ valordastaxasFinal }}
              </h6>
            </v-col>
          </v-row>
          <!-- Linha da Equipamentos -->
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:equipamentos') && !isLoading('get:budget-details')">
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor dos Equipamentos: {{ valordasequipamentos }}
              </h6>
            </v-col>
            <v-col cols="6" class=" justify-start">
              <h6 class="text-h6 blod_color">Valor dos Equipamentos Ajustados: {{ valordasequipamentosFinal }}
              </h6>
            </v-col>
          </v-row>
          <!-- Linha dos orcamentos -->
          <v-row dense class="mt-4 ml-2" v-if="!isLoading('get:evolucoes') && !isLoading('get:budget-details')">
            <v-col cols="6" class="justify-end">
              <h6 class="text-h6 blod_color">Valor Total do Orçamento:<span> {{ valorOrcamentoTotal }} </span>
                <v-icon v-if="valorTotaldaContaAtualziado < 0" color="red">mdi-currency-usd-off</v-icon>
                <v-icon v-else color="green">mdi-currency-usd</v-icon>
              </h6>
            </v-col>
            <v-col cols="6" class=" justify-end">
              <h6 class="text-h6 blod_color">Valor Total do Orçamento Ajustado : {{ valorTotaldoOrcamentoAjustado }}
                <v-icon v-if="valorTotaldaContaAtualziadoComDesconto < 0" color="red">mdi-currency-usd-off</v-icon>
                <v-icon v-else color="green">mdi-currency-usd</v-icon>
              </h6>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Resumo dos Procedimentos -->
      <v-tab-item>
        <div>
          <v-row dense class="mt-4">
            <v-col cols="4">
              <h6 class="text-h6 blod_color">Resumo dos Procedimentos:</h6>
            </v-col>
            <v-col cols="1" v-if="isLoading('get:evolucoes') || isLoading('get:budget-details')">
              <div class="pre-loading d-flex justify-end">
                <v-progress-circular indeterminate color="primary"></v-progress-circular>
              </div>
            </v-col>
          </v-row>
          <div class=" pre-loading" v-if="isLoading('get:evolucoes') || isLoading('get:budget-details')">
            <v-progress-circular indeterminate color="primary"></v-progress-circular>
          </div>
          <v-row dense class="mt-4" v-else>
            <v-col :cols="12">
              <v-data-table :headers="headers_proc.filter(header => !(selectedRow.manutencao && header.value === 'edit'))"
                :items="abstract" :search="search">
                <template v-slot:top>
                  <v-row>
                    <v-col cols="6">
                      <v-text-field dense label="Pesquisar" v-model="search" clearable outlined></v-text-field>
                    </v-col>
                  </v-row>
                </template>
                <template v-slot:[`item.procedimento`]="{ item }">
                  <span >
                    {{ item.nomecomercial ?  `${item.procedimento} - (${item.nomecomercial})` : item.procedimento }}
                  </span>
                </template>
                <template v-slot:[`item.unit_price_formatted`]="{ item }">
                  {{ formatCurrencyZero(item.unit_price) }}
                </template>
                <template v-slot:[`item.frequencia`]="{ item }">
                  {{ item.cobranca === 'NÃO' ? '' : item.frequencia }}
                </template>
                <template v-slot:[`item.sub_total`]="{ item }">
                  {{ item.cobranca === 'NÃO' ? '' : formatCurrencyZero(item.unit_price * item.count) }}
                </template>
                <template v-slot:[`item.final_price`]="{ item }">
                  {{ item.cobranca === 'NÃO' ? '' : formatCurrencyZero(calcFinalPriceAjustado(item)) }}
                </template>
                <template v-slot:[`item.discount_formatted`]="{ item }">
                  {{ item.cobranca === 'NÃO' ? '' : item.discount_formatted }}
                </template>
                <template v-slot:[`item.addition_formatted`]="{ item }">
                  {{ item.cobranca === 'NÃO' ? '' : item.addition_formatted }}
                </template>
                <template v-slot:[`item.validSignaturesCount`]="{ item }">
                  <span v-if="item.validSignaturesCount" :style="{ color: 'green', fontWeight: 'bold' }">
                    {{ item.validSignaturesCount }}
                  </span>
                  <span v-else-if="item.internacao > 0" :style="{ color: 'green', fontWeight: 'bold' }">
                    {{ item.internacao }}
                  </span>
                  <span v-else>
                    {{ '' }}
                  </span>
                </template>
                <template v-slot:[`item.cobranca`]="{ item }">
                  <span :class="{ 'text-green': item.cobranca === 'SIM', 'text-red': item.cobranca === 'NÃO' }">
                    {{ item.cobranca }}
                  </span>
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectGroupProcedure(item, 'updateAll')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.orcamento`]="{ item }">
                  {{ item.aditivo === 'NÃO' ? '' : item.orcamento }}
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Procedimentos -->
      <v-tab-item>
        <div>
          <v-row>
            <v-col>
              <v-data-table :loading="isLoading('get:evolucoes')" :search="search" :headers="filteredHeadersEvolucoes"
                :items="evolucoes" :expanded.sync="expanded" show-expand>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="py-4 grey lighten-2  text-start">
                    <Evolucaoexpand :item="item" />
                  </td>
                </template>
                <template v-slot:top>
                  <v-row class="mt-4">
                    <v-col>
                      <h6 class="text-h6 blod_color">Procedimentos:</h6>
                    </v-col>
                    <v-col class="justify-end mb-4 d-flex" cols="10">
                      <ConfirmButton v-if="!selectedRow.manutencao" outlined color="error" :block="false" class="mx-2 my-0 py-0 py-0"
                        :onConfirm="deleteTreatments" :loading="isLoading('delete:treatments')">
                        Excluir
                      </ConfirmButton>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <ConfirmButton v-if="!selectedRow.manutencao" outlined color="green" :block="false" class="mx-2 my-0 py-0 py-0"
                              :onConfirm="recalcularProcedimento" :loading="isLoading('procedimento:recalcular')">
                              Recalcular
                            </ConfirmButton>
                          </span>
                        </template>
                        <span>Clique para Recalcular o Valor de Cobrança.</span>
                      </v-tooltip>              
                      <v-btn dark dense outlined class="mx-2 my-0 py-0 py-0" color="orange" @click="collapseAll">
                        Recolher Linhas
                      </v-btn>
                      <v-btn v-if="selectedRows.length > 0" dark dense outlined class="mx-2 my-0 py-0 py-0" color="blue"
                        @click="selectAllTreatments">
                        Desmarcar todos
                        <v-icon right> mdi-checkbox-marked-outline </v-icon>
                      </v-btn>
                      <v-btn v-if="!selectedRow.manutencao && selectedRows.length <= 0" dark dense outlined
                        class="mx-2 my-0 py-0 py-0" color="blue" @click="selectAllTreatments">
                        Marcar todos
                        <v-icon right>mdi-checkbox-blank-outline </v-icon>
                      </v-btn>
                      <v-btn v-if="!selectedRow.manutencao" vdark dense outlined class="mx-2 my-0 py-0 py-0" color="green"
                        @click="dialog.create = 'create'">
                        Gerar Procedimentos
                      </v-btn>
                      <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree"
                        @click="refreshPage('evolucao')">
                        Atualizar Tela
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-text-field dense outlined class="mx-5 my-0 py-0 py-0" label="Pesquisar" v-model="search"
                      clearable></v-text-field>
                  </v-row>
                </template>
                <template v-slot:[`item.select_budget_evolucao`]="{ item }"> 
                  <div class="d-flex align-center justify-center">
                    <template v-if="(item.nead_abemid && (item.abemid || item.nead || item.pps || item.casa || item.captacao)) || (!item.nead_abemid && (item.assinatura || (item.procedimento && item.procedimento.procedimento_id === 55 && item.timestamp !== null)))">
                      <v-menu eager offset-x left offset-y bottom v-model="menuAbemid[item.id]">
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            class="align-center"
                            color="green"
                            size="30"
                            v-bind="attrs"
                            v-on="on"
                            @click="handlePrinterClick(item)"
                          >
                            mdi-printer
                          </v-icon>
                        </template>
                        <v-container class="container">
                          <!-- Se for nead_abemid e tiver qualquer um dos outros critérios -->
                          <div v-if="item.nead_abemid">
                            <div v-if="item.abemid">
                              <Abemid :selectedBudgets="selectedOrcamentoId" :idEvolucao="selectedEvolucao"></Abemid>
                            </div>
                            <div v-if="item.casa">
                              <Domicilio :selectedBudgets="selectedOrcamentoId" :idEvolucao="selectedEvolucao"></Domicilio>
                            </div>
                            <div v-if="item.pps">
                              <PPSPaliativo :selectedBudgets="selectedOrcamentoId" :idEvolucao="selectedEvolucao"></PPSPaliativo>
                            </div>
                            <div v-if="item.nead">
                              <Nead :selectedBudgets="selectedOrcamentoId" :idEvolucao="selectedEvolucao"></Nead>
                            </div>
                            <div v-if="item.captacao">
                              <Captacao :selectedBudgets="selectedOrcamentoId" :idEvolucao="selectedEvolucao"></Captacao>
                            </div>
                          </div>

                          <!-- Se não for nead_abemid e tiver assinatura ou procedimento_id === 55 -->
                          <div v-else-if="!item.nead_abemid"> 
                            <div v-if="item.assinatura || (item.procedimento && item.procedimento.procedimento_id === 55 && item.timestamp !== null)">
                              <Evolucao 
                                :selectedBudgets="selectedOrcamentoId" 
                                :evolutions="selectedEvolucao" 
                                :data_inicio="selectedRow?.data_inicio"
                                :data_fim="selectedRow?.data_fim" 
                                :pacienteId="selectedRow"
                                :procedimento_id="procediemntosID" 

                              ></Evolucao>
                            </div>
                          </div>
                          
                        </v-container>
                      </v-menu>
                    </template>
                  </div>
                </template>

                <template v-slot:[`item.assinatura`]="{ item }" >
                  <v-tooltip v-if="item.lat && item.lng" top color="green">
                    <template v-slot:activator="{ on, attrs }"> 
                      <v-icon color="green" v-bind="attrs" v-on="on" @click="openMapInNewTab(item.lat, item.lng)" class="clickable-cursor">
                        mdi-check-circle-outline
                      </v-icon>
                    </template>
                    <span>Clique para Abrir no Mapa</span>
                  </v-tooltip>
                  <v-icon v-else-if=" (item.procedimento.procedimento_avaliacao && item.timestamp != null)  || (!item.procedimento.procedimento_avaliacao && item.procedimento.especial && item.data_especial != null) || (item.procedimento.procedimento_id === 55 && item.timestamp !== null)" color="green">
                    mdi-check-circle-outline
                  </v-icon>
                  <!-- Este v-if é para exibir nada quando não há assinatura -->
                  <template v-else-if="!item.assinatura"></template>
                </template>
                <template v-slot:[`item.formattedHoraInicio`]="{ item }">
                  {{ formatHorario(item.formattedHoraInicio, item.formattedHoraFim) }}
                </template>
                <template v-slot:[`item.pode_excluir`]="{ item }">
                  <div v-if="(![55, 49, 52, 51, 48, 50, 14].includes(item.procedimento.procedimento_id) || item.timestamp === null)">
                    <v-checkbox v-if="item.pode_excluir" v-model="selectedRows" :value="item.pode_excluir"></v-checkbox>
                  </div>
                </template>
                <template v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectProcedure(item, 'update')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.formattedTimestamp`]="{ item }"> 
                  <span v-if="! item.procedimento.procedimento_avaliacao && item.timestamp">
                      {{`${item.formattedTimestamp} - ${formatHorario(item.formattedHoraInicio, item.formattedHoraFim)}`}}
                  </span>
                  <span v-if="!item.procedimento.procedimento_avaliacao && item.procedimento.especial && item.data_especial != null">
                      {{item.formatedDate}}
                  </span>
                  <span v-if="item.procedimento.procedimento_avaliacao && item.timestamp != null">
                      {{`${item.formattedTimestamp}`}}
                  </span>
                </template>
                <template v-slot:[`item.procedimento.procedimento`]="{ item }">
                  <span >
                    {{ item.procedimento.nomecomercial ?  `${item.procedimento.procedimento} - "${item.procedimento.nomecomercial}"` : item.procedimento.procedimento }}
                  </span>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                  <span :class="{ 'text-green': item.ativo, 'text-red': !item.ativo }">
                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                  </span>
                </template>
                <template v-slot:[`item.cobranca`]="{ item }">
                  <span v-if="item.ativo" :class="{ 'text-green': item.cobranca, 'text-red': !item.cobranca }">
                    {{ item.cobranca ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_cobranca) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_pagamento`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_pagamento) }}
                  </span>
                </template>
                <template v-slot:[`item.pagamento`]="{ item }">
                  <span v-if="item.ativo" :class="{ 'text-green': item.pagamento, 'text-red': !item.pagamento }">
                    {{ item.pagamento ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.offline`]="{ item }">
                  <span v-if="item.ativo" :class="{ 'text-green': item.offline, 'text-red': !item.offline }">
                    {{ item.offline ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.aditivo`]="{ item }">
                  <span v-if="item.ativo" :class="{ 'text-green': item.aditivo, 'text-red': !item.aditivo }">
                    {{ item.aditivo ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.nr_aditivo`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.nr_aditivo }}
                  </span>
                </template>
                <template v-slot:[`item.quantidade`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.quantidade }}
                  </span>
                </template>
                <template v-slot:[`item.desconto`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto > 0 ? formatNumber(item.desconto) : '' }}
                  </span>
                </template>
                <template v-slot:[`item.acrescimo`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.acrescimo > 0 ? formatNumber(item.acrescimo) : '' }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_final`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto > 0 || item.acrescimo > 0 ? calculateAdjustedPrice(item) :
                      formatCurrencyZero(item.valor_cobranca) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_total`]="{ item }">
                  <span v-if="item.ativo">
                    {{ calculateAdjustedPriceTotal(item) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
       <!-- Medicamento -->
      <v-tab-item>
        <div>
          <v-row>
            <v-col>
              <v-data-table :loading="isLoading('get:matmed')" :search="search" :headers="headersMatMed"
                :items="matmed">
                <template v-slot:top>
                  <v-row class="mt-4">
                    <v-col>
                      <h6 class="text-h6 blod_color">Mat./Med./Dieta:</h6>
                    </v-col>
                    <!-- Botões -->
                    <v-col class="justify-end mb-4 d-flex" cols="10">
                      <ConfirmButton outlined color="error" :block="false" :onConfirm="deleteMedicamento"
                        :loading="isLoading('delete:medicamento')">
                        Excluir
                      </ConfirmButton>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                             <ConfirmButton v-if="!selectedRow.manutencao" outlined color="green" :block="false" class="mx-2 my-0 py-0 py-0"
                                :onConfirm="recalcularmatmed" :loading="isLoading('matmed:recalcular')">
                                Atualizar Código
                              </ConfirmButton>
                          </span>
                        </template>
                        <span>Clique para Recalcular o Valor de Cobrança.</span>
                      </v-tooltip>    
                      <v-btn dark dense outlined class="mx-2 my-0 py-0 py-0" color="blue" @click="selectAllMatMed">
                        <template v-if="selectedMedicamento.length === matmed.length">
                          Desmarcar todos
                          <v-icon right>mdi-checkbox-marked-outline</v-icon>
                        </template>
                        <template v-else>
                          Marcar todos
                          <v-icon right>mdi-checkbox-blank-outline</v-icon>
                        </template>
                      </v-btn>
                      <v-btn v-if="!selectedRow.manutencao" vdark dense outlined class="mx-2 my-0 py-0 py-0" color="green"
                        @click="openCreateMatMedDialog">
                        Gerar Mat./Med./Dieta
                      </v-btn>
                      <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree"
                        @click="refreshPage('matmed')">
                        Atualizar Tela
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-text-field dense outlined class="mx-5 my-0 py-0 py-0" label="Pesquisar" v-model="search"
                      clearable></v-text-field>
                  </v-row>
                </template>
                <!-- Parte de detalhamento dos Equipamentos -->
                <template v-if="!selectedRow.manutencao" v-slot:[`item.pode_excluir_matmeds`]="{ item }">
                  <v-checkbox :input-value="Array.isArray(selectedMedicamento) && selectedMedicamento.includes(item.id)"
                    @change="toggleSelectedMatMed(item)"></v-checkbox>
                </template>
                <template v-if="!selectedRow.manutencao" v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectMatMed(item, 'updateMatMed')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.matmed`]="{ item }">
                  <span >
                    {{ item?.nome }}
                  </span>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                  <span :class="{ 'text-green': item.ativo, 'text-red': !item.ativo }">
                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                  </span>
                </template>
                <template v-slot:[`item.data_inicio_matmed`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_inicio_matmed) }}
                  </span>
                </template>
                <template v-slot:[`item.data_fim_matmed`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_fim_matmed) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_matmed`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_cobranca_matmed) }}
                  </span>
                </template>
                <template v-slot:[`item.quantidade_matmed`]="{ item }">
                  <span v-if="item.ativo"> {{ item.quantidade_matmed }}
                  </span>
                </template>
                <template v-slot:[`item.desconto_matmed`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto_matmed > 0 ? formatNumber(item.desconto_matmed) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.acrescimo_matmed`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.acrescimo_matmed > 0 ? formatNumber(item.acrescimo_matmed) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.aditivo_matmed`]="{ item }">
                  <span v-if="item.ativo"
                    :class="{ 'text-green': item.aditivo_matmed, 'text-red': !item.aditivo_matmed }">
                    {{ item.aditivo_matmed ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.nr_aditivo`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.nr_aditivo }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_final_matmed`]="{ item }">
                  <span v-if="item.ativo">
                    {{ formatCurrencyZero(item.valor_cobranca_final_matmed) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Taxa -->
      <v-tab-item>
        <div>
          <v-row>
            <v-col>
              <v-data-table :loading="isLoading('get:taxas')" :search="search" :headers="headersTaxas" :items="taxas">
                <template v-slot:top>
                  <v-row class="mt-4">
                    <v-col>
                      <h6 class="text-h6 blod_color">Taxas:</h6>
                    </v-col>
                    <!-- Botões -->
                    <v-col class="justify-end mb-4 d-flex" cols="10">
                      <ConfirmButton outlined color="error" :block="false" :onConfirm="deleteTaxas"
                        :loading="isLoading('delete:taxas')">
                        Excluir
                      </ConfirmButton>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                            <ConfirmButton v-if="!selectedRow.manutencao" outlined color="green" :block="false" class="mx-2 my-0 py-0 py-0"
                              :onConfirm="recalcularTaxa" :loading="isLoading('taxa:recalcular')">
                              Recalcular
                            </ConfirmButton>
                          </span>
                        </template>
                        <span>Clique para Recalcular o Valor de Cobrança.</span>
                      </v-tooltip>    
                      <v-btn dark dense outlined class="mx-2 my-0 py-0 py-0" color="blue" @click="selectAllTaxas">
                        <template v-if="selectedTaxas.length === taxas.length">
                          Desmarcar todos
                          <v-icon right>mdi-checkbox-marked-outline</v-icon>
                        </template>
                        <template v-else>
                          Marcar todos
                          <v-icon right>mdi-checkbox-blank-outline</v-icon>
                        </template>
                      </v-btn>
                      <v-btn v-if="!selectedRow.manutencao" vdark dense outlined class="mx-2 my-0 py-0 py-0" color="green"
                        @click="openCreateTaxaDialog">
                        Gerar Taxa
                      </v-btn>
                      <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree" @click="refreshPage('taxa')">
                        Atualizar Tela
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-text-field dense outlined class="mx-5 my-0 py-0 py-0" label="Pesquisar" v-model="search"
                      clearable></v-text-field>
                  </v-row>
                </template>
                <!-- Parte de detalhamento das taxas -->
                <template v-if="!selectedRow.manutencao" v-slot:[`item.pode_excluir_taxas`]="{ item }">
                  <v-checkbox :input-value="selectedTaxas.includes(item.id)"
                    @change="toggleSelectedTaxa(item)"></v-checkbox>
                </template>
                <template v-if="!selectedRow.manutencao" v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectTaxa(item, 'updateTaxa')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.taxa.taxa.nome`]="{ item }">
                  <span >
                    {{ item.taxa.nomecomercial ?  `${item.taxa.taxa.nome} - (${item.taxa.nomecomercial})` : item.taxa.taxa.nome }}
                  </span>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                  <span :class="{ 'text-green': item.ativo, 'text-red': !item.ativo }">
                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                  </span>
                </template>
                <template v-slot:[`item.data_inicio_taxa`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_inicio_taxa) }}
                  </span>
                </template>
                <template v-slot:[`item.data_fim_taxa`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_fim_taxa) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_taxa`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_cobranca_taxa) }}
                  </span>
                </template>
                <template v-slot:[`item.quantidade_taxa`]="{ item }">
                  <span v-if="item.ativo"> {{ item.quantidade_taxa }}
                  </span>
                </template>
                <template v-slot:[`item.desconto_taxa`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto_taxa > 0 ? formatNumber(item.desconto_taxa) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.acrescimo_taxa`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.acrescimo_taxa > 0 ? formatNumber(item.acrescimo_taxa) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.aditivo_taxa`]="{ item }">
                  <span v-if="item.ativo" :class="{ 'text-green': item.aditivo_taxa, 'text-red': !item.aditivo_taxa }">
                    {{ item.aditivo_taxa ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.nr_aditivo`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.nr_aditivo }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_final_taxa`]="{ item }">
                  <span v-if="item.ativo">
                    {{ formatCurrencyZero(item.valor_cobranca_final_taxa) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Equipamento -->
      <v-tab-item>
        <div>
          <v-row>
            <v-col>
              <v-data-table :loading="isLoading('get:equipamentos')" :search="search" :headers="headersEquipamentos"
                :items="equipamentos">
                <template v-slot:top>
                  <v-row class="mt-4">
                    <v-col>
                      <h6 class="text-h6 blod_color">Equipamentos:</h6>
                    </v-col>
                    <!-- Botões -->
                    <v-col class="justify-end mb-4 d-flex" cols="10">
                      <ConfirmButton outlined color="error" :block="false" :onConfirm="deleteEquipamentos"
                        :loading="isLoading('delete:equipamentos')">
                        Excluir
                      </ConfirmButton>
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                             <ConfirmButton v-if="!selectedRow.manutencao" outlined color="green" :block="false" class="mx-2 my-0 py-0 py-0"
                                :onConfirm="recalcularEquipamento" :loading="isLoading('equipamento:recalcular')">
                                Recalcular
                              </ConfirmButton>
                          </span>
                        </template>
                        <span>Clique para Recalcular o Valor de Cobrança.</span>
                      </v-tooltip>    
                      <v-btn dark dense outlined class="mx-2 my-0 py-0 py-0" color="blue" @click="selectAllEquipamentos">
                        <template v-if="selectedEquipamentos.length === equipamentos.length">
                          Desmarcar todos
                          <v-icon right>mdi-checkbox-marked-outline</v-icon>
                        </template>
                        <template v-else>
                          Marcar todos
                          <v-icon right>mdi-checkbox-blank-outline</v-icon>
                        </template>
                      </v-btn>
                      <v-btn v-if="!selectedRow.manutencao" vdark dense outlined class="mx-2 my-0 py-0 py-0" color="green"
                        @click="openCreateEquipamentoDialog">
                        Gerar Equipamento
                      </v-btn>
                      <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree"
                        @click="refreshPage('equipamento')">
                        Atualizar Tela
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-text-field dense outlined class="mx-5 my-0 py-0 py-0" label="Pesquisar" v-model="search"
                      clearable></v-text-field>
                  </v-row>
                </template>
                <!-- Parte de detalhamento dos Equipamentos -->
                <template v-if="!selectedRow.manutencao" v-slot:[`item.pode_excluir_equipamentos`]="{ item }">
                  <v-checkbox :input-value="selectedEquipamentos.includes(item.id)"
                    @change="toggleSelectedEquipamento(item)"></v-checkbox>
                </template>
                <template v-if="!selectedRow.manutencao" v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectEquipamento(item, 'updateEquipamento')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.equipamento.equipamento.nome`]="{ item }">
                  <span >
                    {{ item.equipamento.nomecomercial ?  `${item.equipamento.equipamento.nome} - (${item.equipamento.nomecomercial})` : item.equipamento.equipamento.nome }}
                  </span>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                  <span :class="{ 'text-green': item.ativo, 'text-red': !item.ativo }">
                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                  </span>
                </template>
                <template v-slot:[`item.data_inicio_equipamento`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_inicio_equipamento) }}
                  </span>
                </template>
                <template v-slot:[`item.data_fim_equipamento`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_fim_equipamento) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_equipamento`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_cobranca_equipamento) }}
                  </span>
                </template>
                <template v-slot:[`item.quantidade_equipamento`]="{ item }">
                  <span v-if="item.ativo"> {{ item.quantidade_equipamento }}
                  </span>
                </template>
                <template v-slot:[`item.desconto_equipamento`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto_equipamento > 0 ? formatNumber(item.desconto_equipamento) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.acrescimo_equipamento`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.acrescimo_equipamento > 0 ? formatNumber(item.acrescimo_equipamento) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.aditivo_equipamento`]="{ item }">
                  <span v-if="item.ativo"
                    :class="{ 'text-green': item.aditivo_equipamento, 'text-red': !item.aditivo_equipamento }">
                    {{ item.aditivo_equipamento ? 'Sim' : 'Não' }}
                  </span>
                </template>
                <template v-slot:[`item.nr_aditivo`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.nr_aditivo }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_final_equipamento`]="{ item }">
                  <span v-if="item.ativo">
                    {{ formatCurrencyZero(item.valor_cobranca_final_equipamento) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
       <!-- Pacote -->
      <v-tab-item>
        <div>
          <v-row>
            <v-col>
              <v-data-table :loading="isLoading('get:pacote')" :search="search" :headers="headersPacotes" :items="pacotes"
                :expanded.sync="expanded" show-expand>
                <template v-slot:expanded-item="{ headers, item }">
                  <td :colspan="headers.length" class="py-4 grey lighten-2  text-start">
                    <Pacoteexpand :item="item" :pacote-id="item.pacote.id" :idoperadora="item.pacote.operadora.id" />
                  </td>
                </template>
                <template v-slot:top>
                  <v-row class="mt-4">
                    <v-col>
                      <h6 class="text-h6 blod_color">Pacotes:</h6>
                    </v-col>
                    <v-col class="justify-end mb-4 d-flex" cols="10">
                      <v-tooltip top>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on">
                             <ConfirmButton v-if="!selectedRow.manutencao" outlined color="green" :block="false" class="mx-2 my-0 py-0 py-0"
                              :onConfirm="recalcularPacote" :loading="isLoading('pacote:recalcular')">
                              Recalcular
                            </ConfirmButton>
                          </span>
                        </template>
                        <span>Clique para Recalcular o Valor de Cobrança.</span>
                      </v-tooltip>           
                      <v-btn vdark dense outlined class="mx-2 my-0 py-0 py-0" color="gree" @click="refreshPage('pacote')">
                        Atualizar Tela
                      </v-btn>
                    </v-col>
                  </v-row>
                  <v-row class="mt-4">
                    <v-text-field dense outlined class="mx-5 my-0 py-0 py-0" label="Pesquisar" v-model="search"
                      clearable></v-text-field>
                  </v-row>
                </template>
                <!-- Parte de detalhamento das pacotes -->

                <template v-slot:[`item.pode_excluir`]="{ item }">
                  <v-checkbox v-if="item.pode_excluir" v-model="selectedRows" :value="item.pode_excluir"></v-checkbox>
                </template>
                <template v-if="!selectedRow.manutencao" v-slot:[`item.edit`]="{ item }">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon v-bind="attrs" v-on="on" @click="onSelectProcedure(item, 'updatePacote')">
                        <v-icon color="green">mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Clique para editar</span>
                  </v-tooltip>
                </template>

                <template v-slot:[`item.nome`]="{ item }">
                  <span>
                    {{ item.pacote.nomecomercial ?  `${item.pacote.nome} - (${item.pacote.nomecomercial})` : item.pacote.nome }}
                  </span>
                </template>
                <template v-slot:[`item.ativo`]="{ item }">
                  <span :class="{ 'text-green': item.ativo, 'text-red': !item.ativo }">
                    {{ item.ativo ? 'Ativo' : 'Inativo' }}
                  </span>
                </template>
                <template v-slot:[`item.data_inicio_pacote`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_inicio_pacote) }}
                  </span>
                </template>
                <template v-slot:[`item.data_fim_pacote`]="{ item }">
                  <span v-if="item.ativo"> {{ formatDate(item.data_fim_pacote) }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_pacote`]="{ item }">
                  <span v-if="item.ativo"> {{ formatCurrencyZero(item.valor_cobranca_pacote) }}
                  </span>
                </template>
                <template v-slot:[`item.quantidade_pacote`]="{ item }">
                  <span v-if="item.ativo"> {{ item.quantidade_pacote }}
                  </span>
                </template>
                <template v-slot:[`item.desconto_pacote`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.desconto_pacote > 0 ? formatNumber(item.desconto_pacote) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.acrescimo_pacote`]="{ item }">
                  <span v-if="item.ativo">
                    {{ item.acrescimo_pacote > 0 ? formatNumber(item.acrescimo_pacote) : '0' }}
                  </span>
                </template>
                <template v-slot:[`item.valor_cobranca_final_pacote`]="{ item }">
                  <span v-if="item.ativo">
                    {{ formatCurrencyZero(item.valor_cobranca_final_pacote) }}
                  </span>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </div>
      </v-tab-item>
      <!-- Autorização -->
      <v-tab-item>
        <p></p>
        <h2 class="blod_color">Autorizações</h2>
        <p></p>
        <h3 class="blod_color">Carregar autorização: </h3>
        <p></p>
        <v-row dense class="my-3">
          <v-col md="3">
            <v-select outlined dense v-model="selectedDocumentType" :items="documentTypes" item-text="nome"
              item-value="id">
              <template v-slot:label>
                <span>Tipo de autorização <span style="color: red;">*</span></span>
              </template>
            </v-select>
          </v-col>
          <v-col md="6">
            <v-file-input v-model="selectedFile" counter show-size persistent-hint dense outlined @change="checkFileSize"
              ref="fileInput">
              <template v-slot:label>
                <span>Anexar autorização <span style="color: red;">*</span></span>
              </template>
            </v-file-input>
          </v-col>
          <v-col md="3">
            <v-tooltip top :disabled="!!(selectedDocumentType && selectedFile)">
              <template v-slot:activator="{ on, attrs }">
                <span v-on="on" v-bind="attrs">
                  <v-btn @click="uploadDocument" :color="isFormValid ? 'success' : 'grey'" :loading="isUploading"
                    :disabled="!isFormValid || isUploading">
                    <v-icon left>mdi-upload</v-icon>
                    Enviar autorização
                  </v-btn>
                </span>
              </template>
              <span>Escolha o "Tipo de autorização" e carregue o arquivo para habilitar este botão.</span>
            </v-tooltip>
          </v-col>
        </v-row>
        <!-- <v-progress-linear v-if="isUploading" :indeterminate="true" color="blue"></v-progress-linear> -->
        <!-- Parte inferior: listar e baixar documentos -->
        <h2 class="blod_color mt-5 mb-5">Autorizações carregadas:</h2>
        <v-list dense>
          <v-progress-linear v-if="isUploading" loading-text="Carregando Informações...." :indeterminate="true"
            color="blue"></v-progress-linear>
          <v-list-item-group v-for="(doc, index) in uploadedDocuments" :key="index">
            <v-list-item class="my-2">
              <v-list-item-content>
                <v-list-item-title class="mb-2">
                  <span class="font-weight-bold text-primary blod_color ">{{ doc.tipodoc.nome }}</span>
                  <br>
                  <span>{{ extractFileName(doc.arquivo) }}</span>
                </v-list-item-title>
                <v-list-item-subtitle>
                  Carregado em: {{ formatarData(doc.data_carregado) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn small color="green" class="white--text" :href="doc.arquivo" target="_blank">
                  <v-icon left>mdi-download</v-icon>
                  Baixar
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-tab-item>
     
    </v-tabs-items>
    <!-- Edição de Procedimento no Resumo-->
    <FullscreenDialog v-model="dialog.updateAll" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Procedimento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.updateAll = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedProcedure">
          <ProcedurePriceForm :onSubmit="updateProceduresPrice" :currentItem="selectedProcedure" :procedures="evolucoes"
            :frequencies="frequencias" :statusOptions="statusOptions" :loading="isLoading('put:procedures')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Edição de Procedimento -->
    <FullscreenDialog v-model="dialog.update" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Procedimentos
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.update = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedProcedure">
          <ProcedureForm :onSubmit="updateProcedures" :currentItem="selectedProcedure" :procedures="procedimentos"
            :frequencies="frequencias" :statusOptions="statusOptions" :ativoOptions="ativoOptions"
            :loading="isLoading('put:procedures')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Edição de Pacote -->
    <FullscreenDialog v-model="dialog.updatePacote" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Pacote
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.updatePacote = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedProcedure">
          <ProcedureFormPacote :onSubmit="updatePacotes" :currentItem="selectedProcedure" :statusOptions="statusOptions"
            :ativoOptions="ativoOptions" :loading="isLoading('put:pacote')" :dataInicio="selectedRow.data_inicio"
            :dataFim="selectedRow.data_fim" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Edição de Taxa -->
    <FullscreenDialog v-model="dialog.updateTaxa" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Taxa
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.updateTaxa = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedTaxa">
          <ProcedureFormTaxa :onSubmit="updateTaxa" :currentItem="selectedTaxa" :statusOptions="statusOptions"
            :ativoOptions="ativoOptions" :loading="isLoading('put:taxa')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Edição de Equipamento -->
    <FullscreenDialog v-model="dialog.updateEquipamento" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Equipamento
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.updateEquipamento = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedEquipamento">
          <ProcedureFormEquipamento :onSubmit="updateEquipamento" :currentItem="selectedEquipamento"
            :statusOptions="statusOptions" :ativoOptions="ativoOptions" :loading="isLoading('put:equipamento')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Edição de Medicamento -->
    <FullscreenDialog v-model="dialog.updateMatMed" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Edição de Mat./Med./Dieta:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.updateMatMed = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text v-if="selectedMedicamento">
          <ProcedureFormMatMed :onSubmit="updateMatMed" :currentItem="selectedMedicamento" :frequencies="frequencias" 
            :statusOptions="statusOptions" :ativoOptions="ativoOptions" :loading="isLoading('put:matmed')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Geração de Procedimento -->
    <FullscreenDialog v-model="dialog.create" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Gerar Procedimentos:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.create = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <GenerateProceduresForm :proceduresToCreate="proceduresToCreate" :treatmentFrequency="treatmentFrequency"
            :budgetId="selectedRow.id" :procedimentos="procedimentos" :statusOptions="statusOptions"
            :frequencias="frequencias" :treatmentsToCreate="treatmentsToCreate" :onSubmit="createTreatments"
            :canCreateTreatments="canCreateTreatments" :dataInicio="selectedRow.data_inicio" :orcamentoId="selectedRow.id"
            :dataFim="selectedRow.data_fim" :loading="isLoading('put:treatments')" :operadoraId="selectedRow.operadora_id"
            :modalidade="selectedRow.modalidade_o" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Geração de Taxa -->
    <FullscreenDialog v-model="dialog.createTaxas" max-width="90%">
      <v-card>
        <v-card-title class="blod_color">
          Gerar Taxa:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.createTaxas = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>
          <GenerateProceduresFormTaxa :operadoraId="selectedRow.operadora_id" :statusOptions="statusOptions"
            :orcamentoId="selectedRow.id" :onSubmit="createTaxa" :dataInicio="selectedRow.data_inicio"
            :modalidade="selectedRow.modalidade_o" :dataFim="selectedRow.data_fim" :loading="isLoading('pot:novaTaxa')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Geração de Equipamento -->
    <FullscreenDialog v-model="dialog.createEquipamentos" max-width="90%" >
      <v-card>
        <v-card-title class="blod_color sticky-header">
          Gerar Equipamento:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.createEquipamentos = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>

          <GenerateProceduresFormEquipamento :operadoraId="selectedRow.operadora_id" :statusOptions="statusOptions"
            :orcamentoId="selectedRow.id" :onSubmit="createEquipamento" :dataInicio="selectedRow.data_inicio"
            :modalidade="selectedRow.modalidade_o" :dataFim="selectedRow.data_fim"
            :loading="isLoading('pot:novaEquipamento')" :paciente="selectedRow?.paciente_o?.id"/>
        </v-card-text>
      </v-card>
    </FullscreenDialog>
    <!-- Geração de Mat./Med./Dieta -->
    <FullscreenDialog v-model="dialog.createMedicamentos" max-width="90%" >
      <v-card>
        <v-card-title class="blod_color sticky-header">
          Gerar Mat./Med./Dieta:
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog.createMedicamentos = false">
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
        </v-card-title>
        <v-divider />
        <v-card-text>

          <GenerateProceduresFormMedicamento :operadoraId="selectedRow.operadora_id" :statusOptions="statusOptions" :frequencias="frequencias"
            :orcamentoId="selectedRow.id" :onSubmit="createMedicamento" :dataInicio="selectedRow.data_inicio"
            :modalidade="selectedRow.modalidade_o" :dataFim="selectedRow.data_fim" :loading="isLoading('post:novaMedicamento')" />
        </v-card-text>
      </v-card>
    </FullscreenDialog>
  </div>
</template>

<script>
import api from "../../../http";
import UtilsFunc from "../../../service/utilsFunc";
import '../../../assets/css/styles.css';

const { numberToCurrency } = UtilsFunc
export default {
  props: {
    onsubmit: Function,
    onDeleteSession: Function,
    budgetId: Number,
    updateSession: Function,
    filteredData: {
      type: Array,
      default: () => []
    },
  },
  components: {
    ConfirmButton: () => import('../../ConfirmButton.vue'),
    FullscreenDialog: () => import('../../FullscreenDialog.vue'),
    ProcedureForm: () => import('./ProcedureForm.vue'),
    GenerateProceduresForm: () => import('./GenerateProceduresForm.vue'),
    Evolucaoexpand: () => import('./Evolucaoexpand.vue'),
    Pacoteexpand: () => import('./Pacoteexpand.vue'),
    ProcedurePriceForm: () => import('./ProcedurePriceForm.vue'),
    AditivoIndividual: () => import('./../../relatorios/aditivo/AditivoIndividual.vue'),
    Orcamento: () => import('./../../relatorios/orcamento/Orcamento.vue'),
    ProcedureFormPacote: () => import('./ProcedureFormPacote.vue'),
    ProcedureFormTaxa: () => import('./ProcedureFormTaxa.vue'),
    GenerateProceduresFormTaxa: () => import('./GenerateProceduresFormTaxa.vue'),
    ProcedureFormEquipamento: () => import('./ProcedureFormEquipamento.vue'),
    ProcedureFormMatMed: () => import('./ProcedureFormMedicamento.vue'),
    GenerateProceduresFormEquipamento: () => import('./GenerateProceduresFormEquipamento.vue'),
    GenerateProceduresFormMedicamento: () => import('./GenerateProceduresFormMedicamento.vue'),
    Abemid: () => import('../../relatorios/avaliacao/Abemid_Orcamento.vue'),
    PPSPaliativo: () => import('../../relatorios/avaliacao/Paliativo_Orcamento.vue'),
    Domicilio: () => import('../../relatorios/avaliacao/Casa_Orcamento.vue'),
    Nead: () => import('../../relatorios/avaliacao/Nead_Orcamento.vue'),
    Captacao: () => import('../../relatorios/avaliacao/Captacao_Orcamento.vue'),
    Evolucao: () => import('../../relatorios/evolucoes/EvolucoesEditForOrcamento.vue'),

  },
  data: () => UtilsFunc.withCRUDUtils({
    search: '',
    operadoras: [],
    empresa: [],
    motivosreprovacao: [],
    pacientes: [],
    procedimentos: [],
    frequencias: [],
    evolucoes: [],
    taxas: [],
    equipamentos: [],
    matmed: [],
    totalValorCobrancaTaxa: '',
    totalValorCobrancaFinalTaxa: '',
    totalValorCobrancaEquipamento: '',
    totalValorCobrancaFinalEquipamento: '',
    pacotes: [],
    pacote: null,
    menu: false,
    IdOrcamento: [],
    evolucoesResumo: [],
    selectedRows: [],
    selectedTaxas: [],
    selectedEquipamentos: [],
    selectedMedicamento: [],
    selectedProcedure: {},
    selectedRow: {},
    selectedTaxa: {},
    selectedEquipamento: {},
    documentTypes: [],
    selectedDocumentType: null,
    uploadedDocuments: [],
    isUploading: false,
    isActives: true,
    selectedFile: null,
    loading_pac: false,
    currentTab: 0,
    aditivo: [],
    maxNrAditivo: '',
    maxDataAditivo: '',
    uniqueNrAditivo: '',
    isLoadingEvolucoes: false,
    isDataLoaded: false,
    dialog: {
      updateAll: false,
      updatePacote: false,
      updateTaxa: false,
      createTaxas: false,
      updateEquipamento: false,
      updateMatMed: false,
      createEquipamentos: false,
      createMedicamentos: false,
    },
    statusOptions: [
      { nome: "SIM", value: true },
      { nome: "Não", value: false },
    ],
    ativoOptions: [
      { nome: "Ativo", value: true },
      { nome: "Inativo", value: false },
    ],
    proceduresToCreate: [{
      frequencia: null,
      procedimento: null,
      quantidade: 1,
      qtd: 1,
      pagamento: true,
      cobranca: true,
      especial: true,
    }],
    modalidades: [],
    tipoitem: [],
    motivoreprovacao: [],
    motivo_suspensao_atendimento: [],
    hospital: [],
    expanded: [],
    expandedPacote: [],
    isEditingTreatments: false,
    requiredField: [e => (e !== null && e !== undefined && e !== '') || 'Obrigatório'],
    menuAbemid: {},
    selectedEvolucao: 0,
    evolutions: 0,
    selectedOrcamentoId: 0,
    procediemntosID: [],
    headers_proc: [
      { text: "Editar", value: "edit" },
      { text: "Tipo", value: "tipoItemNome", align: "center" },
      { text: 'Orçamento ou Aditivo', value: 'orcamento' },
      { text: 'Nº Aditivo', value: 'nr_aditivo' },
      { text: 'Procedimento', value: 'procedimento', width: 250 },
      { text: "Código", value: "codigo" },
      { text: 'Frequência', value: 'frequencia' },
      { text: 'QTD Procedimentos', value: 'count' },
      { text: 'QTD Validadas', value: 'validSignaturesCount' },
      { text: 'Valor Unitário', value: 'unit_price_formatted' },
      { text: 'Cobrança', value: 'cobranca' },
      { text: 'Valor Total Orçado', value: 'sub_total' },
      { text: '% Desconto', value: 'discount_formatted' },
      { text: '% Acrescimo', value: 'addition_formatted' },
      { text: 'Valor Final Orçado', value: 'final_price' },
    ],
    headersEvolucoes: [
      { text: "Editar", value: "edit" },
      { text: "ID", value: "id" },
      { text: "Procedimento", value: "procedimento.procedimento",  width: 250},
      { text: "Frequência", value: "frequencia.nome" },
      { text: "Profissional", value: "profissional.apelido" },
      { text: "Evolução / Execução", value: "formattedTimestamp", align: 'center', width: 120 },
      { text: "Assinatura", value: "assinatura", align: 'center' },
      { text: "Status", value: "ativo" },
      { text: "Cobrança", value: "cobranca" },
      { text: "QTD", value: "quantidade" },
      { text: "Valor de cobrança", value: "valor_cobranca" },
      { text: '% Desconto', value: 'desconto' },
      { text: '% Acrescimo', value: 'acrescimo' },
      { text: 'Valor de cobrança final', value: 'valor_cobranca_final', width: 100 },
      { text: 'Valor Total', value: 'valor_total', width: 150 },
      { text: "Aditivo", value: "aditivo" },
      { text: "Nrº do Aditivo", value: "nr_aditivo" },
      { text: "Offline", value: "offline" },
      { text: "Colaborador Offline", value: "colaborador_offline_nome" },
      { text: "PDF", align: "center", sortable: false, value: "select_budget_evolucao" },
      { text: "Excluir", value: "pode_excluir" },
    ],
    headersPacotes: [
      { text: "Editar", value: "edit" },
      { text: "ID", value: "id" },
      { text: "Pacote", value: "nome" },
      { text: "Código", value: "pacote.codigo" },
      { text: "Status", value: "ativo" },
      { text: "Data de Início", value: "data_inicio_pacote" },
      { text: "Data de Término", value: "data_fim_pacote" },
      { text: "Valor de cobrança", value: "valor_cobranca_pacote" },
      { text: "Qtd", value: "quantidade_pacote" },
      { text: '% Desconto', value: 'desconto_pacote' },
      { text: '% Acrescimo', value: 'acrescimo_pacote' },
      { text: "Total", value: "valor_cobranca_final_pacote" },
    ],
    headersTaxas: [
      { text: "Editar", value: "edit" },
      { text: "ID", value: "id" },
      { text: "Taxa", value: "taxa.taxa.nome", width: 250 },
      { text: "Código", value: "taxa.codigo" },
      { text: "Status", value: "ativo" },
      { text: "Data de Início", value: "data_inicio_taxa" },
      { text: "Data de Término", value: "data_fim_taxa" },
      { text: "Valor de cobrança", value: "valor_cobranca_taxa" },
      { text: "Qtd", value: "quantidade_taxa" },
      { text: '% Desconto', value: 'desconto_taxa' },
      { text: '% Acrescimo', value: 'acrescimo_taxa' },
      { text: "Total", value: "valor_cobranca_final_taxa" },
      { text: "Aditivo", value: "aditivo_taxa" },
      { text: "Nrº do Aditivo", value: "nr_aditivo" },
      { text: "Excluir", value: "pode_excluir_taxas" },
    ],
    headersEquipamentos: [
      { text: "Editar", value: "edit" },
      { text: "ID", value: "id" },
      { text: "Equipamento", value: "equipamento.equipamento.nome", width: 250 },
      { text: "Código", value: "equipamento.codigo" },
      { text: "Status", value: "ativo" },
      { text: "Data de Início", value: "data_inicio_equipamento" },
      { text: "Data de Término", value: "data_fim_equipamento" },
      { text: "Valor de cobrança", value: "valor_cobranca_equipamento" },
      { text: "Qtd", value: "quantidade_equipamento" },
      { text: '% Desconto', value: 'desconto_equipamento' },
      { text: '% Acrescimo', value: 'acrescimo_equipamento' },
      { text: "Total", value: "valor_cobranca_final_equipamento" },
      { text: "Aditivo", value: "aditivo_equipamento" },
      { text: "Nrº do Aditivo", value: "nr_aditivo" },
      { text: "Excluir", value: "pode_excluir_equipamentos" },
    ],
    headersMatMed: [
      { text: "Editar", value: "edit" },
      { text: "ID", value: "id" },
      { text: "Tipo", value: "matmed.tipo.tipo", width: 100 },
      { text: "Produto", value: "matmed.nome", width: 250 },
      { text: "Frequência", value: "frequencia.nome" },
      { text: "Código", value: "codigo" },
      { text: "Status", value: "ativo" },
      { text: "Data de Início", value: "data_inicio_matmed" },
      { text: "Data de Término", value: "data_fim_matmed" },
      { text: "Valor de cobrança", value: "valor_cobranca_matmed" },
      { text: "Qtd", value: "quantidade_matmed" },
      { text: '% Desconto', value: 'desconto_matmed' },
      { text: '% Acrescimo', value: 'acrescimo_matmed' },
      { text: "Total", value: "valor_cobranca_final_matmed" },
      { text: "Aditivo", value: "aditivo_matmed" },
      { text: "Nrº do Aditivo", value: "nr_aditivo" },
      { text: "Excluir", value: "pode_excluir_matmeds" },
    ],
    treatmentsToCreate: 0,
    treatmentFrequency: {
      // para incializar com SIM os dois campos
      cobranca: true,
      pagamento: true,
    },
  }),
  methods: {
    async fetchData() {
      await this.getBudgetDetails();
      await this.getEvolucoes();
      this.isDataLoaded = true;
    },
    async getBudgetDetails() {
      const LOADING_NAME = 'get:budget-details'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get(`atendimentos/sessoes/?orcamento=${this.budgetId}`);
        // console.log(data)
        this.selectedRow = data[0];
        if (this.selectedRow) {
          this.selectedRow.paciente_o = this.safeParse(this.selectedRow.paciente_obj);
          this.selectedRow.operadora_o = this.safeParse(this.selectedRow.operadora_obj);
          this.selectedRow.modalidade_o = this.safeParse(this.selectedRow.modalidade);
          this.selectedRow.motivoreprovacao_o = this.safeParse(this.selectedRow.motivoreprovacao);
          this.selectedRow.motivo_suspensao_atendimento_o = this.safeParse(this.selectedRow.motivo_suspensao_atendimento);
          this.selectedRow.hospital_o = this.safeParse(this.selectedRow.hospital);
          this.selectedRow.manutencao = this.selectedRow.manutencao === `1`;
          this.selectedRow.reprovado = this.selectedRow.reprovado === `1`;
        }
      } catch (error) {
        console.error('Erro ao buscar detalhes do orçamento.', error);
        this.$toast.error('Erro ao buscar detalhes do orçamento. Por favor, tente novamente.');
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getEmpresa() {
      const LOADING_NAME = 'get:tableValues'
      this.setLoading(LOADING_NAME)
      try {
        const { data } = await api.get('/multi_empresas/empresas/')
        this.empresa = data.filter(item => item.filial == 2)
        // console.log(this.empresa)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    safeParse(jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (error) {
        console.error('Erro na string JSON:', jsonString);
        return null;
      }
    },
    async getOperadoras() {
      const { data } = await api.get("operadoras/operadoras/");
      this.operadoras = data.filter(operadora => operadora.ativo);
    },
    async getMotivoReprovacao() {
      try {
        const { data } = await api.get("uteis/motivoreprovacao/");
        this.motivoreprovacao = data
      } catch (error) {
        alert("Erro ao listar os Motivos de Reprovação")
        console.log(error)
      }

    },
    async getMotivoSuspensaoAtendimento() {
      try {
        const { data } = await api.get("uteis/motivosuspensaoatendimento/");
        this.motivo_suspensao_atendimento = data.filter(item => item.ativo)
      } catch (error) {
        alert("Erro ao listar os Motivos de Suspensõa dos Atendimentos")
        console.log(error)
      }
    },
    async getHospital() {
      try {
        const { data } = await api.get("uteis/hospital/");
        this.hospital = data.filter(item => item.ativo)
      } catch (error) {
        alert("Erro ao listar os Hospitais")
        console.log(error)
      }
    },
    async getChildrensOperadoras() {
      this.getPacientes();
      this.getProcedimentos();
      this.getMotivoReprovacao();
    },
    async getModalidades() {
      try {
        const { data } = await api.get('uteis/modalidades/');
        this.modalidades = data.filter(modalidade => modalidade.ativo)
      } catch (error) {
        alert("Erro ao listar as Operadoras")
        console.log(error)
      }
    },
    async getTipoItem() {
      try {
        const { data } = await api.get('uteis/tipoitem/');
        this.tipoitem = data.filter(item => item.ativo);
        // console.log('Tipos de Item carregados:', this.tipoitem);  // Debug
      } catch (error) {
        alert("Erro ao listar os tipos de itens");
        console.log(error);
      }
    },
    async getPacientes() {
      if (!this.selectedRow.operadora_id) return;
      this.loading_pac = true
      const LOADING_NAME = 'get:pacientes'
      this.setLoading(LOADING_NAME)
      const ope_obj = JSON.parse(this.selectedRow?.operadora_obj)
      try {
        const { data } = await api.get(
          `pacientes/listar/?operadora=${ope_obj.id}&ativo=1`
        );
        this.pacientes = data;
      } catch (error) {
        alert("Erro no link ao solicitar equipe");
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.loading_pac = false
      }
    },
    async getPacote() {
      const LOADING_NAME = 'get:pacote'
      this.setLoading(LOADING_NAME)
      const sessao_id = JSON.parse(this.budgetId)
      // console.log(sessao_id)
      try {
        const { data } = await api.get(`atendimentos/pacotes/?orcamento=${sessao_id}`)
        this.pacotes = data
        if (data.length > 0) {
          this.pacote = data[0];
        }
        // console.log('PAcote ', this.pacotes)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async getProcedimentos() {
      const ope_obj = JSON.parse(this.selectedRow?.operadora_obj)
      try {
        const { data } = await api.get(`operadoras/operadora-procedimentos/?operadora=${ope_obj.id}`)
        // console.log('Procediemnto Por Operadora -> ', data)
        this.procedimentos = data
          .filter(procedimento => procedimento.ativo === true)
          .sort((a, b) => a.procedimento.nome.localeCompare(b.procedimento.nome));
      } catch (error) {
        alert("Erro no link ao solicitar os procedimentos Ativos")
      }
    },
    async getTaxas() {
      const LOADING_NAME = 'get:taxas'
      this.setLoading(LOADING_NAME)
      this.isLoadingEvolucoes = true;
      const sessao_id = JSON.parse(this.budgetId)
      try {
        const { data } = await api.get(`atendimentos/taxas/?orcamento=${sessao_id}`)
        const sortedTaxa = data.sort((a, b) => a.taxa.taxa.nome.localeCompare(b.taxa.taxa.nome));
        this.taxas = sortedTaxa.sort((a, b) => b.ativo - a.ativo);
        // console.log(this.taxas)
        // Filtrar as taxas ativas
        const activeTaxas = data.filter(taxa => taxa.ativo);

        // Somar os valores de `valor_cobranca_taxa` e `valor_cobranca_final_taxa`
        this.totalValorCobrancaTaxa = activeTaxas.reduce((sum, taxa) => sum + (taxa.valor_cobranca_taxa || 0), 0);
        this.totalValorCobrancaFinalTaxa = activeTaxas.reduce((sum, taxa) => sum + (taxa.valor_cobranca_final_taxa || 0), 0);
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isLoadingEvolucoes = false;
      }
    },
    async getEquipamentos() {
      const LOADING_NAME = 'get:equipamentos'
      this.setLoading(LOADING_NAME)
      this.isLoadingEvolucoes = true;
      const sessao_id = JSON.parse(this.budgetId)
      try {
        const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${sessao_id}`)
        // console.log(data)
        const sortedByName = data.sort((a, b) => a.equipamento.equipamento.nome.localeCompare(b.equipamento.equipamento.nome));
        this.equipamentos = sortedByName.sort((a, b) => b.ativo - a.ativo);
        // console.log('Equipamentos do Orçamento --> ', this.equipamentos)
        // Filtrar os equipamentos ativas
        const activeEquipamentos = data.filter(equipamento => equipamento.ativo);

        // Somar os valores de `valor_cobranca_equipamento` e `valor_cobranca_final_equipamento`
        this.totalValorCobrancaEquipamento = activeEquipamentos.reduce((sum, equipamento) => sum + (equipamento.valor_cobranca_equipamento || 0), 0);
        this.totalValorCobrancaFinalEquipamento = activeEquipamentos.reduce((sum, equipamento) => sum + (equipamento.valor_cobranca_final_equipamento || 0), 0);
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isLoadingEvolucoes = false;
      }
    },
    async getMatMed() {
      // console.log('aqui...')
      const LOADING_NAME = 'get:matmed'
      this.setLoading(LOADING_NAME)
      this.isLoadingEvolucoes = true;
      const sessao_id = JSON.parse(this.budgetId)
      try {
        const response = await api.get(`atendimentos/matmeds/?orcamento=${sessao_id}`)
        this.matmed = response.data
          .sort((a, b) => {
          // 1️⃣ Ordenar por ativo (true primeiro)
          if (a.ativo !== b.ativo) {
            return b.ativo - a.ativo;
          }

          // 2️⃣ Ordenar por matmed.tipo.id (menor ID primeiro)
          if (a.matmed.tipo.id !== b.matmed.tipo.id) {
            return a.matmed.tipo.id - b.matmed.tipo.id;
          }

          // 3️⃣ Ordenar por matmed.nome (ordem alfabética)
          return a.matmed.nome.localeCompare(b.matmed.nome);
        });

        // console.log(this.matmed)
        
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isLoadingEvolucoes = false;
      }
    },
    async recalcularTaxa() {
      const LOADING_NAME = 'taxa:recalcular';
      this.setLoading(LOADING_NAME);
      try {
        const ope_obj = JSON.parse(this.selectedRow?.operadora_obj);
        const { data: taxas } = await api.get(`operadoras/operadora-taxas/?operadora=${ope_obj.id}`);
        const taxasAtivas = taxas.filter(item => item.ativo === true);
        const sessao_id = JSON.parse(this.budgetId);
        const { data: taxaOrcamento } = await api.get(`atendimentos/taxas/?orcamento=${sessao_id}`);
        const updatePromises = taxaOrcamento.map(async (taxaOrc) => {
        const taxaAtualizada = taxasAtivas.find(taxa => taxa.id === taxaOrc.taxa.id);
        if (taxaAtualizada) {
          const novoValorCobranca = parseFloat(taxaAtualizada.preco);
          let valorCobrancaFinal = novoValorCobranca;

            // Calcular o valor de cobrança final considerando desconto ou acréscimo
            if (taxaOrc.desconto_taxa !== null && taxaOrc.desconto_taxa !== 0) {
              valorCobrancaFinal -= (novoValorCobranca * (taxaOrc.desconto_taxa / 100));
            } else if (taxaOrc.acrescimo_taxa !== null && taxaOrc.acrescimo_taxa !== 0) {
              valorCobrancaFinal += (novoValorCobranca * (taxaOrc.acrescimo_taxa / 100));
            }

            // Multiplicar pela quantidade de taxas
            valorCobrancaFinal *= taxaOrc.quantidade_taxa;
            valorCobrancaFinal = Math.round(valorCobrancaFinal * 100) / 100; 
          try {
            await api.put(`atendimentos/taxa/${taxaOrc.id}/`, {
              valor_cobranca_taxa: novoValorCobranca,
              valor_cobranca_final_taxa: valorCobrancaFinal
            });
          } catch (updateError) {
            console.error(`Erro ao atualizar taxa ID: ${taxaOrc.id}`, updateError);
          }
        } else {
          console.error(`Taxa ID ${taxaOrc.taxa.id} não encontrada nas taxas ativas`);
        }
        });
        await Promise.all(updatePromises);
        await this.getTaxas()
        this.$toast.success('Valores de cobrança recalculados com sucesso!');
      } catch (error) {
        this.$toast.error(`Desculpe, aconteceu algum erro ao recalcular os valores de cobrança. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async recalcularPacote() {
      const LOADING_NAME = 'pacote:recalcular';
      this.setLoading(LOADING_NAME);
      try {
        const ope_obj = JSON.parse(this.selectedRow?.operadora_obj);
        const { data: pacotes } = await api.get(`operadoras/operadora-pacotes/?operadora=${ope_obj.id}`);
        const pacotesAtivos = pacotes.filter(item => item.ativo === true);
        const sessao_id = JSON.parse(this.budgetId);
        const { data: pacoteOrcamento } = await api.get(`atendimentos/pacotes/?orcamento=${sessao_id}`);
        
        const updatePromises = pacoteOrcamento.map(async (pacoteOrc) => {
          const pacoteAtualizado = pacotesAtivos.find(pacote => pacote.id === pacoteOrc.pacote.id);
          if (pacoteAtualizado) {
            const novoValorCobranca = parseFloat(pacoteAtualizado.preco);
            let valorCobrancaFinal = novoValorCobranca;

            // Calcular o valor de cobrança final considerando desconto ou acréscimo
            if (pacoteOrc.desconto_pacote !== null && pacoteOrc.desconto_pacote !== 0) {
              valorCobrancaFinal -= (novoValorCobranca * (pacoteOrc.desconto_pacote / 100));
            } else if (pacoteOrc.acrescimo_pacote !== null && pacoteOrc.acrescimo_pacote !== 0) {
              valorCobrancaFinal += (novoValorCobranca * (pacoteOrc.acrescimo_pacote / 100));
            }

            // Multiplicar pela quantidade de pacotes
            valorCobrancaFinal *= pacoteOrc.quantidade_pacote;
            valorCobrancaFinal = Math.round(valorCobrancaFinal * 100) / 100; // Ajustando precisão

            try {
              await api.put(`atendimentos/pacote/${pacoteOrc.id}/`, {
                valor_cobranca_pacote: novoValorCobranca,
                valor_cobranca_final_pacote: valorCobrancaFinal
              });
              // console.log(`Atualização do pacote ID ${pacoteOrc.id} bem-sucedida: Valor final: ${valorCobrancaFinal}`);
            } catch (updateError) {
              console.error(`Erro ao atualizar pacote ID: ${pacoteOrc.id}`, updateError);
            }
          } else {
            console.error(`Pacote ID ${pacoteOrc.pacote.id} não encontrado nas pacotes ativas`);
          }
        });

        await Promise.all(updatePromises);
        await this.getPacote();
        this.$toast.success('Valores de cobrança recalculados com sucesso!');
      } catch (error) {
        this.$toast.error(`Desculpe, aconteceu algum erro ao recalcular os valores de cobrança. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    }, 
    async recalcularmatmed() {
      const LOADING_NAME = 'matmed:recalcular';
      this.setLoading(LOADING_NAME);
      try{ 
        const { data: matmed } = await api.get(`matmed/matmeds/`);
        const matmedAtivos = matmed.filter(item => item.ativo === true);
        // console.log(matmedAtivos)
        const sessao_id = JSON.parse(this.budgetId);
        const { data: matmedOrcamentoTodos } = await api.get(`atendimentos/matmeds/?orcamento=${sessao_id}`);
        const matmedOrcamento = matmedOrcamentoTodos.filter(item => item .ativo)
        // console.log(matmedOrcamento)
        const ope_obj = JSON.parse(this.selectedRow?.operadora_obj);
        const { data: codigoProprio } = await api.get(`matmed/tabela_propria/`);
        const tabelapropria = codigoProprio.filter(item => item.ativo === true && item.operadora.id === ope_obj.id);
        // console.log('Dados',tabelapropria)
        const updatePromises = matmedOrcamento.map(async (matmedOrc) => {
        const matmedAtualizada = matmedAtivos.find(equipamento => equipamento.id === matmedOrc.matmed.id);

        // Aqui você precisa encontrar o item da tabela própria que tenha o mesmo produto.id
        const tabelaEncontrada = tabelapropria.find(item => item.produto?.id === matmedAtualizada?.id);

        // console.log('matmed', matmedAtualizada?.id);
        // console.log('tabelaEncontrada', tabelaEncontrada?.produto?.id);

        if (matmedAtualizada) {
          const codigo = 
            (tabelaEncontrada ? tabelaEncontrada.codigo : (
              matmedAtualizada?.tabela_medicamento ?
                (matmedAtualizada?.tabela_medicamento.cod_TUSS || matmedAtualizada?.tabela_medicamento.cod_bransindice) :
                (matmedAtualizada?.tabela_simpro?.cod_TUSS || matmedAtualizada?.tabela_simpro?.cod_simpro)
            ));

          // console.log('codigo', codigo);

          try {
            await api.put(`atendimentos/matmed/${matmedOrc.id}/`, {
              codigo: codigo
            });
          } catch (updateError) {
            console.error(`Erro ao atualizar equipamento ID: ${matmedOrc.id}`, updateError);
          }
        } else {
          console.error(`Equipamento ID ${matmedOrc.equipamento.id} não encontrado em equipamentos ativos`);
        }
      });

        await Promise.all(updatePromises);
        await this.getMatMed()
        this.$toast.success('Valores de cobrança recalculados com sucesso!');
      } catch (error) {
        this.$toast.error(`Desculpe, aconteceu algum erro ao recalcular os valores de cobrança. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async recalcularEquipamento() {
      const LOADING_NAME = 'equipamento:recalcular';
      this.setLoading(LOADING_NAME);
      try {
        const ope_obj = JSON.parse(this.selectedRow?.operadora_obj);
        const { data: equipamentos } = await api.get(`operadoras/operadora-equipamentos/?operadora=${ope_obj.id}`);
        const equipamentosAtivas = equipamentos.filter(item => item.ativo === true);
        const sessao_id = JSON.parse(this.budgetId);
        const { data: equipamentoOrcamento } = await api.get(`atendimentos/equipamentos/?orcamento=${sessao_id}`);
        const updatePromises = equipamentoOrcamento.map(async (equipamentoOrc) => {
        const equipamentoAtualizada = equipamentosAtivas.find(equipamento => equipamento.id === equipamentoOrc.equipamento.id);
        if (equipamentoAtualizada) {
          const novoValorCobranca = parseFloat(equipamentoAtualizada.preco);
          let valorCobrancaFinal = novoValorCobranca;

            // Calcular o valor de cobrança final considerando desconto ou acréscimo
            if (equipamentoOrc.desconto_equipamento !== null && equipamentoOrc.desconto_equipamento !== 0) {
              valorCobrancaFinal -= (novoValorCobranca * (equipamentoOrc.desconto_equipamento / 100));
            } else if (equipamentoOrc.acrescimo_equipamento !== null && equipamentoOrc.acrescimo_equipamento !== 0) {
              valorCobrancaFinal += (novoValorCobranca * (equipamentoOrc.acrescimo_equipamento / 100));
            }

            // Multiplicar pela quantidade de equipamentos
            valorCobrancaFinal *= equipamentoOrc.quantidade_equipamento;
            valorCobrancaFinal = Math.round(valorCobrancaFinal * 100) / 100; 
          try {
            await api.put(`atendimentos/equipamento/${equipamentoOrc.id}/`, {
              valor_cobranca_equipamento: novoValorCobranca,
              valor_cobranca_final_equipamento: valorCobrancaFinal
            });
          } catch (updateError) {
            console.error(`Erro ao atualizar equipamento ID: ${equipamentoOrc.id}`, updateError);
          }
        } else {
          console.error(`equipamento ID ${equipamentoOrc.equipamento.id} não encontrada nas equipamentos ativas`);
        }
        });
        await Promise.all(updatePromises);
        await this.getEquipamentos()
        this.$toast.success('Valores de cobrança recalculados com sucesso!');
      } catch (error) {
        this.$toast.error(`Desculpe, aconteceu algum erro ao recalcular os valores de cobrança. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async recalcularProcedimento() {
      const LOADING_NAME = 'procedimento:recalcular';
      this.setLoading(LOADING_NAME);
      try {
        const ope_obj = JSON.parse(this.selectedRow?.operadora_obj);
        const { data: procedimentos } = await api.get(`operadoras/operadora-procedimentos/?operadora=${ope_obj.id}`);
        const procedimentosAtivos = procedimentos.filter(item => item.ativo === true);
        // console.log('procedimentosAtivos --> ', procedimentosAtivos)
        const sessao_id = JSON.parse(this.budgetId);
        const { data: procedimentoOrcamento } = await api.get(`atendimentos/evolucoes/?sessao=${sessao_id}`);
        const updatePromises = procedimentoOrcamento.map(async (procedimentoOrc) => {
        const procedimentoAtualizado = procedimentosAtivos.find(procedimento => procedimento.id === procedimentoOrc.procedimento.id);
        if (procedimentoAtualizado) {
          const novoValorCobranca = parseFloat(procedimentoAtualizado.preco);
          let valorCobrancaFinal = novoValorCobranca;

            // Calcular o valor de cobrança final considerando desconto ou acréscimo
            if (procedimentoOrc.desconto !== null && procedimentoOrc.desconto !== 0) {
              valorCobrancaFinal -= (novoValorCobranca * (procedimentoOrc.desconto / 100));
            } else if (procedimentoOrc.acrescimo !== null && procedimentoOrc.acrescimo !== 0) {
              valorCobrancaFinal += (novoValorCobranca * (procedimentoOrc.acrescimo / 100));
            }

            // Multiplicar pela quantidade de procedimentos
            valorCobrancaFinal *= procedimentoOrc.quantidade;
            valorCobrancaFinal = Math.round(valorCobrancaFinal * 100) / 100; 
          try {
            await api.put(`atendimentos/evolucao/${procedimentoOrc.id}/`, {
              desconto: procedimentoOrc.desconto,
              acrescimo: procedimentoOrc.acrescimo,
              valor_cobranca: novoValorCobranca,
              valor_cobranca_final: valorCobrancaFinal
            });
            // console.log(valorCobrancaFinal)
          } catch (updateError) {
            console.error(`Erro ao atualizar procedimento ID: ${procedimentoOrc.id}`, updateError);
          }
        } else {
          console.error(`procedimento ID ${procedimentoOrc.procedimento.id} não encontrada nas procedimentos ativas`);
        }
        });
        await Promise.all(updatePromises);
        await this.getEvolucoes()
        await this.updateSession()
        this.$toast.success('Valores de cobrança recalculados com sucesso!');
      } catch (error) {
        this.$toast.error(`Desculpe, aconteceu algum erro ao recalcular os valores de cobrança. ${error}`);
      } finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async getFrequencias() {
      const { data } = await api.get("atendimentos/frequencias/")
      this.frequencias = data.filter(procedimento => procedimento.ativo)
    },
    async getEvolucoes() {
      await this.getTipoItem();
      const LOADING_NAME = 'get:evolucoes'
      this.setLoading(LOADING_NAME)
      this.isLoadingEvolucoes = true;
      try {
        const { data } = await api.get(`atendimentos/evolucoes/?sessao=${this.selectedRow.id}`)
        // console.log('Evolução --> ',data)
        const evol = data.sort((a, b) => {
          // Primeiro por ativo (ativo = true vem primeiro)
          if (b.ativo !== a.ativo) {
            return b.ativo - a.ativo;
          }
          // Segundo por data_inicio_especial (não nulo vem primeiro)
          if (a.data_inicio_especial && !b.data_inicio_especial) {
            return -1;
          }
          if (!a.data_inicio_especial && b.data_inicio_especial) {
            return 1;
          }
          // Terceiro por assinatura (não nulo vem primeiro)
          if (a.assinatura && !b.assinatura) {
            return -1;
          }
          if (!a.assinatura && b.assinatura) {
            return 1;
          }
          // Finalmente por timestamp crescente
          return new Date(a.timestamp) - new Date(b.timestamp);
        });
        this.evolucoes = evol.map(evolucao => ({
          ...evolucao,
          tipo_item_nome: this.tipoitem.find(tipo => tipo.id === evolucao.tipo_item)?.nome || 'Desconhecido',
          formattedTimestamp: this.formatTimestamp(evolucao.timestamp),
          formatedDate: this.formatarData(evolucao.data_especial),
          formattedHoraInicio: this.formatTime(evolucao.hora_inicio),
          formattedHoraFim: this.formatTime(evolucao.hora_fim)
        }));
      } catch (error) {
        this.$toast.error(`Desculpa, aconteceu algua coisa errada. Erro -> ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
        this.isLoadingEvolucoes = false;
      }
    },
    async getAditivo() {
      this.loadingTaxas = true
      const orcamentoId = this.orcamentoId;
      try {
        const { data } = await api.get(`atendimentos/aditivo/?sessao=${orcamentoId}`);
        this.aditivo = data;
        this.maxNrAditivo = data.nr_aditivo;
        this.maxDataAditivo = this.formatDate(data.data_aditivo);
        // console.log(this.aditivo); // Debug
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro com a parte do Aditivo. ${error}`);
      } finally {
        this.loadingTaxas = false;
      }
    },
    async createTreatments(fields) {
      const LOADING_NAME = 'put:treatments'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('Novo Procedimento --> ', fields)
        await api.post('atendimentos/evolucoes-post/', fields)
        // usado para quando salvar, os campos voltarem ao estado inical já devidamente preenchidos.
        this.treatments = [{
          frequencia: null,
          procedimento: null,
          qqtd: 1,
          pagamento: true,
          cobranca: true,
        }]
        await this.getEvolucoes()
        await this.updateSession()
        this.dialog.create = false
        this.$toast.success('Procedimentos gerados com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    openCreateTaxaDialog() {
      this.dialog.createTaxas = true;
    },
    openCreateEquipamentoDialog() {
      this.dialog.createEquipamentos = true;
    },
    openCreateMatMedDialog() {
      this.dialog.createMedicamentos = true;
    },
    async createTaxa(fields) {
      const LOADING_NAME = 'pot:novaTaxa'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('Nova Taxa --> ', fields)
        await api.post('atendimentos/taxas/', fields)
        await this.getTaxas()
        // await this.updateSession()
        this.dialog.createTaxas = false
        this.$toast.success('Taxa gerada com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async createEquipamento(fields) {
      const LOADING_NAME = 'pot:novaEquipamento'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('Novo Equipamento --> ', fields)
          const { data } = await api.post('atendimentos/equipamentos/', fields);
          await this.getEquipamentos()
          // await this.updateSession()
          this.dialog.createEquipamentos = false
          this.$toast.success('Equipamento gerado com sucesso!')

        // Retorna o equipamento criado (incluindo o ID)
        return data;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async createMedicamento(fields) {
      const LOADING_NAME = 'post:novaMedicamento'
      this.setLoading(LOADING_NAME)
      try {
        // console.log('Novo MatMedDieta --> ', fields)
          const { data } = await api.post('atendimentos/matmed/', fields);
          await this.getMatMed()
          // await this.updateSession()
          this.dialog.createMedicamentos = false
          this.$toast.success('Material / Medicamento / Dieta  gerado com sucesso!')

        // Retorna o MatMedDieta criado (incluindo o ID)
        return data;
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    toggleSelectedTaxa(item) {
      const index = this.selectedTaxas.indexOf(item.id);
      if (index > -1) {
        this.selectedTaxas.splice(index, 1);
      } else {
        this.selectedTaxas.push(item.id);
      }
    },
    toggleSelectedEquipamento(item) {
      const index = this.selectedEquipamentos.indexOf(item.id);
      if (index > -1) {
        this.selectedEquipamentos.splice(index, 1);
      } else {
        this.selectedEquipamentos.push(item.id);
      }
    },
    toggleSelectedMatMed(item) {
    // Se selectedMedicamento for null ou undefined, converte para array vazio
    if (!Array.isArray(this.selectedMedicamento)) {
      this.selectedMedicamento = [];
    }

    const index = this.selectedMedicamento.indexOf(item.id);
    if (index > -1) {
      this.selectedMedicamento.splice(index, 1);
    } else {
      this.selectedMedicamento.push(item.id);
    }
  },
    selectAllTreatments() {
      if (this.selectedRows.length > 0) return this.selectedRows = []
      this.selectedRows = this.evolucoes
        .filter(e => !e.assinatura)
        .map(e => e.id)
    },
    selectAllTaxas() {
      if (this.selectedTaxas.length === this.taxas.length) {
        this.selectedTaxas = [];
      } else {
        this.selectedTaxas = this.taxas.map(e => e.id);
      }
    },
    selectAllEquipamentos() {
      if (this.selectedEquipamentos.length === this.equipamentos.length) {
        this.selectedEquipamentos = [];
      } else {
        this.selectedEquipamentos = this.equipamentos.map(e => e.id);
      }
    },
    selectAllMatMed() {
      if (this.selectedMedicamento.length === this.matmed.length) {
        this.selectedMedicamento = [];
      } else {
        this.selectedMedicamento = this.matmed.map(e => e.id);
      }
    },
    async updateProcedures(fields) {
      const LOADING_NAME = 'put:procedures'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/evolucao/${fields.id}/`, fields)
        await this.getEvolucoes()
        await this.updateSession()

        this.$toast.success('Procedimento realizado com sucesso!')
        this.dialog.update = false
        this.selectedProcedure = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updatePacotes(fields) {
      const LOADING_NAME = 'put:pacote'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/pacote/${fields.id}/`, fields)
        await this.getPacote()
        this.$toast.success('Pacote alterado com sucesso!')
        this.dialog.updatePacote = false
        this.selectedProcedure = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateTaxa(fields) {
      const LOADING_NAME = 'put:taxa'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/taxa/${fields.id}/`, fields)
        await this.getTaxas()
        this.$toast.success('Taxa alterada com sucesso!')
        this.dialog.updateTaxa = false
        this.selectedTaxa = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateEquipamento(fields) {
      const LOADING_NAME = 'put:equipamento'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/equipamento/${fields.id}/`, fields)
        await this.getEquipamentos()
        this.$toast.success('Equipamento alterado com sucesso!')
        this.dialog.updateEquipamento = false
        this.selectedEquipamento = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateMatMed(fields) {
      const LOADING_NAME = 'put:matmed'
      this.setLoading(LOADING_NAME)
      try {
        await api.put(`atendimentos/matmed/${fields.id}/`, fields)
        await this.getMatMed()
        this.$toast.success('Material / Medicamento / Dieta  alterado com sucesso!')
        this.dialog.updateMatMed = false
        this.selectedMedicamento = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async updateProceduresPrice(fields) {
      const LOADING_NAME = 'put:procedures'
      this.setLoading(LOADING_NAME)
      try {
        await Promise.all(fields.map(async (item) =>
          await api.put(`atendimentos/evolucao/${item.id}/`, item)
        ))
        await this.getEvolucoes()
        await this.updateSession()

        this.$toast.success('Procedimento realizado com sucesso!')
        this.dialog.updateAll = false
        this.selectedProcedure = {}
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async deleteTreatments() {
      const LOADING_NAME = 'delete:treatments'
      this.setLoading(LOADING_NAME)
      // Verifica se há linhas selecionadas antes de proceder
      if (this.selectedRows.length === 0) {
        this.$toast.error('Nenhuma linha selecionada para exclusão.');
        this.setLoading(LOADING_NAME, true)
        return;
      }
      try {
        await Promise.allSettled(this.selectedRows.map(row => api.delete(`atendimentos/evolucao/${row}/`)))
        await this.getEvolucoes()
        await this.updateSession()

        this.selectedRows = []
        this.$toast.success('Procedimentos deletados com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async deleteTaxas() {
      const LOADING_NAME = 'delete:taxas'
      this.setLoading(LOADING_NAME)
      // console.log(this.selectedTaxas)
      // Verifica se há linhas selecionadas antes de proceder
      if (this.selectedTaxas.length === 0) {
        this.$toast.error('Nenhuma taxa selecionada para exclusão.');
        this.setLoading(LOADING_NAME, true)
        return;
      }
      try {
        await Promise.allSettled(this.selectedTaxas.map(row => api.delete(`atendimentos/taxa/${row}/`)))
        await this.getTaxas()

        this.selectedTaxas = []
        this.$toast.success('Taxa(s) deletada(s) com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async deleteEquipamentos() {
      const LOADING_NAME = 'delete:equipamentos'
      this.setLoading(LOADING_NAME)
      // console.log(this.selectedEquipamentos)
      // Verifica se há linhas selecionadas antes de proceder
      if (this.selectedEquipamentos.length === 0) {
        this.$toast.error('Nenhum Equipamento selecionado para exclusão.');
        this.setLoading(LOADING_NAME, true)
        return;
      }
      try {
        await Promise.allSettled(this.selectedEquipamentos.map(row => api.delete(`atendimentos/equipamento/${row}/`)))
        await this.getEquipamentos()

        this.selectedEquipamentos = []
        this.$toast.success('Equipamento(s) deletado(s) com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async deleteMedicamento() {
      const LOADING_NAME = 'delete:medicamento'
      this.setLoading(LOADING_NAME)
      // console.log(this.selectedEquipamentos)
      // Verifica se há linhas selecionadas antes de proceder
      if (this.selectedMedicamento.length === 0) {
        this.$toast.error('Nenhum Medicamento selecionado para exclusão.');
        this.setLoading(LOADING_NAME, true)
        return;
      }
      try {
        await Promise.allSettled(this.selectedMedicamento.map(row => api.delete(`atendimentos/matmed/${row}/`)))
        await this.getMatMed()

        this.selectedMedicamento = []
        this.$toast.success('Medicamento  deletado(s) com sucesso!')
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },
    async onSubmit() {
      if (this.$refs.createForm.validate()) {
        // Converte as strings de data para objetos Date
        let dataInicio = new Date(this.selectedRow.data_inicio);
        let dataFim = new Date(this.selectedRow.data_fim);

        // Verifica se a data de início é posterior à data de término
        if (dataInicio > dataFim) {
          this.$toast.error('A data de término não pode ser anterior à data de início.');
          return; // Interrompe a execução do método
        }
        const fields = { ...this.selectedRow }


        if (typeof fields.motivoreprovacao_o === 'number') {
          fields.motivoreprovacao = fields.motivoreprovacao_o;
        }
        if (typeof fields.motivo_suspensao_atendimento_o === 'number') {
          fields.motivo_suspensao_atendimento = fields.motivo_suspensao_atendimento_o;
        }
        if (typeof fields.hospital_o === 'number') {
          fields.hospital = fields.hospital_o;
        }

        if (fields.modalidade?.id) {
          fields.modalidade = fields.modalidade.id
        }
        fields.pacote = fields.pacote_id
        const LOADING_NAME = 'put:sessao'
        this.setLoading(LOADING_NAME)
        await this.onsubmit(fields)
        this.setLoading(LOADING_NAME, true)

      } else {
        this.$toast.error('Por favor, preencha todos os campos obrigatórios antes de salvar.');
      }
    },
    async handleDeleteSession() {
      const LOADING_NAME = 'delete:sessao'
      this.setLoading(LOADING_NAME)
      await this.onDeleteSession()
      this.setLoading(LOADING_NAME, true)
    },
    openMapInNewTab(lat, lng) {
      const url = `https://www.google.com/maps/?q=${lat},${lng}`;
      window.open(url, '_blank');
    },
    unmount() {
      this.selectedRow = {}
      this.isOpen = false
      this.pacientes = []
    },
    formatInterval(item) {
      if (!item.hora_inicio || item.hora_inicio === '00:00:00') {
        return item.hora_fim ? this.formatTime(item.hora_fim) : '';
      }
      if (!item.hora_fim || item.hora_fim === '00:00:00') {
        return this.formatTime(item.hora_inicio);
      }
      return `${this.formatTime(item.hora_inicio)} - ${this.formatTime(item.hora_fim)}`;
    },
    formatTime(value) {
      if (!value) return '';

      const parts = value.split(':');
      return parts[0] + ':' + parts[1];  // HH:MM
    },
    formatDate(isoString) {
      if (!isoString) return '';

      const date = new Date(isoString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0'); // +1 porque os meses vão de 0 a 11
      const year = date.getFullYear();

      return `${day}/${month}/${year}`;
    },
    formatarData(data) {
      if (!data) return '';
      const [ano, mes, dia] = data.split('-');
      return `${dia}/${mes}/${ano}`;
    },
    // Metodo para calcular quando de sessões em decorrencia da frequencia e intervalo da data de inicio e data fim do orçamento
    calculateSuggestedSessions() {
      let start = new Date(this.selectedRow.data_inicio);
      let end = new Date(this.selectedRow.data_fim);
      let diffDays = parseInt((end - start) / (1000 * 60 * 60 * 24)) + 1;
      // Encontre o objeto de frequência selecionado usando o ID
      let selectedFrequencyObj = this.frequencias.find(freq => freq.id === this.treatmentFrequency.frequencia);

      let frequencyFactor = selectedFrequencyObj?.fator;
      if (frequencyFactor) {
        this.treatmentsToCreate = Math.round(diffDays * frequencyFactor);
      }
    },
    onSelectGroupProcedure(item, modal) {
      this.selectedProcedure = { ...item.reference }
      this.dialog[modal] = true
    },
    onSelectProcedure(item, modal) {
      this.selectedProcedure = { ...item }
      this.dialog[modal] = true
    },
    onSelectTaxa(item, modal) {
      this.selectedTaxa = { ...item }
      this.dialog[modal] = true
    },
    onSelectEquipamento(item, modal) {
      this.selectedEquipamento = { ...item }
      this.dialog[modal] = true
    },
    onSelectMatMed(item, modal) {
      this.selectedMedicamento = { ...item }
      this.dialog[modal] = true
    },
    // Método para recolher todoas as linhas expandidadas
    collapseAll() {
      this.expanded = [];
    },
    formatHorario(horaInicio, horaFim) {
      if (horaInicio && horaFim) {
        return `${horaInicio} até ${horaFim}`;
      }
      return ''; // retorna uma string vazia se qualquer campo estiver vazio
    },
    formatCurrencyZero(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },
    formatTimestamp(timestamp) {
      if (timestamp) {
        const date = new Date(timestamp);
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        let weekDay = date.toLocaleDateString('pt-BR', { weekday: 'long' });

        // Remover o sufixo "-feira" para dias de segunda a sexta
        if (!['sábado', 'domingo'].includes(weekDay)) {
          weekDay = weekDay.replace('-feira', '');
        }

        return `${day}/${month}/${year} - ${weekDay.charAt(0).toUpperCase() + weekDay.slice(1)} `;
      }
      return '';
    },
    calcFinalPriceResult({ unit_price, count }) {
      const subTotal = unit_price * count
      return subTotal
    },
    calcFinalPriceAjustado({ unit_price, count, discount, addition }) {
      const subTotal = unit_price * count
      return subTotal - (subTotal * (discount / 100)) + (subTotal * (addition / 100))
    },
    calculoPrecoFinal(item) {
      if (item.cobranca === 'NÃO') {
        return '';
      }

      let finalPrice = item.unit_price;

      if (item.discount) {
        finalPrice -= (finalPrice * (item.discount / 100));
      } else if (item.addition) {
        finalPrice += (finalPrice * (item.addition / 100));
      }

      return this.formatCurrencyZero(finalPrice * item.validSignaturesCount);
    },
    handleStatusChange() {
      if (this.selectedRow.ativo) {
        // Se o orçamento for aprovado, define "reprovado" e demais mudanças
        this.selectedRow.reprovado = false;
        this.selectedRow.motivoreprovacao_o = false;
      }
      if (!this.selectedRow.ativo) {
        this.selectedRow.suspender_atendimento = false;
      }
    },
    handleStatusAtendimentoSuspenso() {
      if (this.selectedRow.suspender_atendimento) {
        // Se o orçamento for aprovado, define "reprovado" e demais mudanças
        this.selectedRow.data_suspender_atendimento = null;
        // this.selectedRow.motivo_suspensao_atendimento_o = false;
        // this.selectedRow.hospital_o = false;
      }
    },
    handleStatusOrcamentoReprovado() {
      if (!this.selectedRow.reprovado) {
        // Se o orçamento for aprovado, define "reprovado" e demais mudanças
        this.selectedRow.motivoreprovacao_o = null;
      }
    },
    async getDocumentTypes() {
      const LOADING_NAME = "get:documentTypes";
      this.setLoading(LOADING_NAME);
      try {
        const { data } = await api.get("/uteis/tipo-autorizacoes/");
        this.documentTypes = data.filter(doctype => doctype.ativo);
      }
      catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      }
      finally {
        this.setLoading(LOADING_NAME, true);
      }
    },
    async uploadDocument() {
      if (!this.selectedDocumentType || !this.selectedFile) {
        this.$toast.error("Por favor, selecione o tipo de documento e o arquivo.");
        return;
      }

      this.isUploading = true;

      let formData = new FormData();
      formData.append("arquivo", this.selectedFile);
      formData.append("tipodoc", this.selectedDocumentType);
      formData.append("orcamento", this.selectedRow.id);
      formData.append("ativo", this.isActives);
      formData.append("sessao", this.selectedRow.id);
      const currentDate = new Date().toISOString().split('T')[0];
      formData.append("data_carregado", currentDate);
      this.ativo = true

      try {
        await api.post("/atendimentos/autorizacao/", formData);
        this.$toast.success("Autorização carregada com sucesso!");
        this.selectedFile = null;
        this.selectedDocumentType = null;
        this.fetchUploadedDocuments();

      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado. Deu erro ${error}`);
      } finally {
        this.isUploading = false;
      }
    },
    // Pegar os documentos do back
    async fetchUploadedDocuments() {
      this.isUploading = true;
      try {
        const { data } = await api.get(`/atendimentos/autorizacao/?${this.selectedRow.id}`);
        // Filtra os documentos para manter apenas aqueles com orcamento igual a selectedRow.id
        const filteredData = data.filter(doc => doc.orcamento === this.selectedRow.id);

        // Ordena os documentos por data de criação
        this.uploadedDocuments = filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Desculpe! Aconteceu algo errado ao buscar as autorizações. Erro: ${error}`);
      } finally {
        this.isUploading = false;
      }
    },
    // Pega os arquivos e mostra na parte inferior da tela os arquivos
    async loadUploadedDocuments() {
      try {
        const { data } = await api.get(`/atendimentos/autorizacao/?${this.selectedRow.id}`);
        // Filtra os documentos para manter apenas aqueles com orcamento igual a selectedRow.id
        const filteredData = data.filter(doc => doc.orcamento === this.selectedRow.id);

        // Ordena os documentos por data de criação
        this.uploadedDocuments = filteredData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
      } catch (error) {
        this.$toast.error(`Erro ao carregar autorização: ${error}`);
      }
    },
    // Retira o caminho do nome do arquivo 
    extractFileName(url) {
      if (typeof url === 'string' && url.length > 0) {
        // Verifica se é uma string válida e não está vazia
        const parts = url.split('/');
        return parts.pop(); // Retorna o último elemento após o split
      }
      return ""; // Retorna uma string vazia se a URL for inválida
    },
    checkFileSize() {
      if (this.selectedFile && this.selectedFile.size > 1000000) { // maior que 1MB
        this.$toast.error("O tamanho do arquivo é maior do que o permitido (1MB).");
        this.selectedFile = null; // remova a referência do arquivo para que o usuário não possa fazer o upload
        this.$refs.fileInput.reset();
      }
    },
    refreshPage(method) {
      switch (method) {
        case 'taxa':
          this.getTaxas();
          break;
        case 'equipamento':
          this.getEquipamentos();
          break;
        case 'pacote':
          this.getPacote();
          break;
        case 'evolucao':
          this.getEvolucoes();
          break;
        case 'orcamento':
          this.getBudgetDetails();
          break;
        case 'matmed':
          this.getMatMed();
          break;
        default:
          this.getBudgetDetails();
          this.getEvolucoes();
          this.getTaxas();
          this.getPacote();
          break;
      }
    },
    formatNumber(value) {
      return parseFloat(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
    },
    calculateAdjustedPrice(item) {
      const basePrice = parseFloat(item.valor_cobranca);
      const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
      const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
      const adjustedPrice = basePrice * (1 + addition - discount);
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    calculateAdjustedPriceTotal(item) {
      const basePrice = parseFloat(item.valor_cobranca);
      const discount = item.desconto ? parseFloat(item.desconto) / 100 : 0;
      const addition = item.acrescimo ? parseFloat(item.acrescimo) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount)) * item.quantidade;
      return `R$ ${adjustedPrice.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    calculoPacote(item) {
      const basePrice = parseFloat(item.valor_cobranca_pacote);
      const discount = item.desconto_pacote ? parseFloat(item.desconto_pacote) / 100 : 0;
      const addition = item.acrescimo_pacote ? parseFloat(item.acrescimo_pacote) / 100 : 0;
      const adjustedPrice = (basePrice * (1 + addition - discount));
      const valorTotal = adjustedPrice * item.quantidade_pacote
      return `R$ ${valorTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
    },
    openEditPage() {
      var pacienteId = this.selectedRow.paciente_id || null;
      if (pacienteId !== null) {
        const url = `/prontuario_paciente?id=${pacienteId}`;
        window.open(url, '_blank');
      }

    },
    handlePrinterClick(item) {
      // Fecha todos os menus antes de abrir o novo
      this.menuAbemid = {}; 
      this.$set(this.menuAbemid, item.id, true); 

      // Atualiza os valores da linha clicada
      this.selectedEvolucao = item.id;
      this.selectedOrcamentoId = item.sessao.id;
      this.procediemntosID = item
    },

  },
  created() {
    this.getDocumentTypes();
  },
  mounted() {
    this.fetchData();
    this.getEmpresa();
    this.getPacote();
    this.getTaxas();
    this.getEquipamentos();
    this.getMatMed();
    this.getModalidades();
    this.getMotivoReprovacao();
    this.getMotivoSuspensaoAtendimento();
    this.getDocumentTypes();
    this.getHospital();
    (async () => {
      await this.getBudgetDetails()
      this.getOperadoras();
      this.getFrequencias();
      this.getTipoItem();
      this.getEvolucoes();
      this.getChildrensOperadoras();
      this.fetchUploadedDocuments();
      this.loadUploadedDocuments();
    })()

  },
  computed: {
    isFormValid() {
      return this.selectedDocumentType && this.selectedFile;
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
    filteredHeadersEvolucoes() {
      if (this.selectedRow.manutencao) {
        // Se manutencao for igual a 1, filtre os cabeçalhos "Editar" e "Excluir"
        return this.headersEvolucoes.filter(header => header.value !== 'edit' && header.value !== 'pode_excluir');
      }
      return this.headersEvolucoes;
    },
    filteredHeadersTaxas() {
      if (this.selectedRow.manutencao) {
        // Se manutencao for igual a 1, filtre os cabeçalhos "Editar" e "Excluir"
        return this.headersTaxas.filter(header => header.value !== 'edit' && header.value !== 'pode_excluir');
      }
      return this.headersTaxas;
    },
    filteredHeadersEquipamentos() {
      if (this.selectedRow.manutencao) {
        // Se manutencao for igual a 1, filtre os cabeçalhos "Editar" e "Excluir"
        return this.headersEquipamentos.filter(header => header.value !== 'edit' && header.value !== 'pode_excluir');
      }
      return this.headersEquipamentos;
    },
    filteredHeadersMatMed() {
      if (this.selectedRow.manutencao) {
        // Se manutencao for igual a 1, filtre os cabeçalhos "Editar" e "Excluir"
        return this.headersMatMed.filter(header => header.value !== 'edit' && header.value !== 'pode_excluir');
      }
      return this.headersMatMed;
    },
    filteredHeadersPacotes() {
      if (this.selectedRow.manutencao) {
        // Se manutencao for igual a 1, filtre os cabeçalhos "Editar" e "Excluir"
        return this.headersPacotes.filter(header => header.value !== 'edit' && header.value !== 'pode_excluir');
      }
      return this.headersPacotes;
    },
    valordosProcedimentos() {
      const total = this.evolucoes
        .filter(item => item.ativo === true && item.procedimento.despesa.id !== 4)
        .reduce((acc, item) => {
          if (item.cobranca)
            acc += this.calcFinalPriceResult({
              unit_price: item.valor_cobranca,
              count: item.quantidade,
            })
          return acc
        }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordastaxas() {
      const total = this.taxas
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.valor_cobranca_taxa,
            count: item.quantidade_taxa,
          })
          return acc
        }, 0)
      // console.log(total)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordasequipamentos() {
      const total = this.equipamentos
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.valor_cobranca_equipamento,
            count: item.quantidade_equipamento,
          })
          return acc
        }, 0)
      // console.log(total)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordosProcedimentosAjustado() {
      const total = this.evolucoes
        .filter(item => item.ativo === true && item.procedimento.despesa.id !== 4)
        .reduce((acc, item) => {
          if (item.cobranca)
            acc += this.calcFinalPriceAjustado({
              unit_price: item.valor_cobranca,
              count: item.quantidade,
              discount: item.desconto,
              addition: item.acrescimo,
            })
          return acc
        }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordastaxasFinal() {
      const total = this.taxas
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceAjustado({
            unit_price: item.valor_cobranca_taxa,
            count: item.quantidade_taxa,
            discount: item.desconto_taxa,
            addition: item.acrescimo_taxa,
          })
          return acc
        }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordasequipamentosFinal() {
      const total = this.equipamentos
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceAjustado({
            unit_price: item.valor_cobranca_equipamento,
            count: item.quantidade_equipamento,
            discount: item.desconto_equipamento,
            addition: item.acrescimo_equipamento,
          })
          return acc
        }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordoPacoteFinal() {
      return this.pacotes.reduce((total, pacote) => {
        return total + parseFloat((pacote.valor_cobranca_final_pacote) || 0);
      }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordoPacote() {
      return this.pacotes.reduce((total, pacote) => {
        return total + parseFloat((pacote.valor_cobranca_pacote * pacote.quantidade_pacote) || 0);
      }, 0).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordosDiarias() {
      // console.log(this.evolucoes.filter(item => item.ativo === true && item.procedimento.despesa.id !== 4))
      const total = this.evolucoes
      .filter(item => item.ativo === true && item.procedimento.despesa.id === 4)
      .reduce((acc, item) => {
        if (item.cobranca)
        acc += this.calcFinalPriceResult({
          unit_price: item.valor_cobranca,
          count: item.quantidade,
        })
        return acc
      }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valordosDiariasAjustado() {
      const total = this.evolucoes
      .filter(item => item.ativo === true && item.procedimento.despesa.id === 4)
      .reduce((acc, item) => {
        if (item.cobranca)
        acc += this. calcFinalPriceAjustado({
          unit_price: item.valor_cobranca,
          count: item.quantidade,
          discount: item.desconto,
          addition: item.acrescimo,
        })
        return acc
      }, 0)
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valorOrcamentoTotal() {
      const taxa = this.taxas
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.valor_cobranca_taxa,
            count: item.quantidade_taxa,
          })
          return acc
        }, 0)
      const equipamento = this.equipamentos
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.valor_cobranca_equipamento,
            count: item.quantidade_equipamento,
          })
          return acc
        }, 0)
      const totaldosProcedimentos = this.evolucoes
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          if (item.cobranca)
            acc += this.calcFinalPriceResult({
              unit_price: item.valor_cobranca,
              count: item.quantidade,
            })
          return acc
        }, 0)

      const totalPacote = parseFloat(this.valordoPacote.replace('R$', '').replace('.', '').replace(',', '.'))
      const resultado = totaldosProcedimentos + totalPacote + taxa + equipamento
      return resultado.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })
    },
    valorTotaldoOrcamentoAjustado() {
      const taxa = this.taxas
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceAjustado({
            unit_price: item.valor_cobranca_taxa,
            count: item.quantidade_taxa,
            discount: item.desconto_taxa,
            addition: item.acrescimo_taxa,
          })
          return acc
        }, 0)
      const equipamento = this.equipamentos
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceAjustado({
            unit_price: item.valor_cobranca_equipamento,
            count: item.quantidade_equipamento,
            discount: item.desconto_equipamento,
            addition: item.acrescimo_equipamento,
          })
          return acc
        }, 0)
      const totalProcedimentos = this.evolucoes
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          if (item.cobranca)
            acc += this.calcFinalPriceAjustado({
              unit_price: item.valor_cobranca,
              count: item.quantidade,
              discount: item.desconto,
              addition: item.acrescimo,
            })
          return acc
        }, 0)
      const totalPacotes = parseFloat(this.valordoPacoteFinal.replace('R$', '').replace('.', '').replace(',', '.'));
      const total = totalProcedimentos + totalPacotes + taxa + equipamento;
      return total.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valorTotaldoOrcamentoComDesconto() {
      const totalProcedimentos = this.evolucoes
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          if (item.cobranca)
            acc += this.calcFinalPriceAjustado({
              unit_price: item.valor_cobranca,
              count: item.quantidade,
              discount: item.desconto,
              addition: item.acrescimo,
            })
          return acc
        }, 0)
      // const totalPacotes = parseFloat(this.valordoPacote.replace('R$', '').replace('.', '').replace(',', '.'));
      // const total = totalProcedimentos + totalPacotes;
      return totalProcedimentos.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    },
    valorTotaldoCusto() {
      const taxa = this.taxas
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.taxa.taxa.custo,
            count: item.quantidade_taxa,
          });
          return acc;
        }, 0);
      const equipamento = this.equipamentos
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          acc += this.calcFinalPriceResult({
            unit_price: item.equipamento.equipamento.custo,
            count: item.quantidade_equipamento,
          });
          return acc;
        }, 0);
      const procedimento = this.evolucoes
        .filter(item => item.ativo === true)
        .reduce((acc, item) => {
          if (item.pagamento)
            acc += this.calcFinalPriceResult({
              unit_price: item.valor_pagamento,
              count: item.quantidade,
            });
          return acc;
        }, 0);
      const total = taxa + procedimento + equipamento
      const formattedTotal = total.toFixed(2);
      const currencyString = formattedTotal.replace('.', ',');
      // console.log('ValorTotalCusto',currencyString)
      return currencyString;
    },
    valorTotaldaContaAtualziado() {
      const custo = parseFloat(this.valorTotaldoCusto.replace(',', '.'));
      const percentual = (((this.empresa[0]?.custo_fixo + this.empresa[0]?.imposto + this.empresa[0]?.rentabilidade) / 100)).toFixed(2)
      const procedimento = parseFloat(this.valordosProcedimentos.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const pacote = parseFloat(this.valordoPacote.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const taxa = parseFloat(this.valordastaxas.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const equipamento = parseFloat(this.valordasequipamentos.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const resultadoTotal = ((pacote + procedimento + taxa + equipamento) - (((pacote + procedimento + taxa + equipamento) * percentual) + custo))
      // console.log('ValorTotalOrcamentoSEMDESCONTO', resultadoTotal)
      return resultadoTotal
    },
    valorTotaldaContaAtualziadoComDesconto() {
      const custo = parseFloat(this.valorTotaldoCusto.replace(',', '.'));
      const percentual = (((this.empresa[0]?.custo_fixo + this.empresa[0]?.imposto + this.empresa[0]?.rentabilidade) / 100)).toFixed(2);
      const procedimentos = parseFloat(this.valorTotaldoOrcamentoComDesconto.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const pacote = parseFloat(this.valordoPacoteFinal.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const taxa = parseFloat(this.valordastaxasFinal.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const equipamento = parseFloat(this.valordasequipamentosFinal.replace('R$', '').replace(/\./g, '').replace(',', '.').trim());
      const resultadoTotal = ((pacote + procedimentos + taxa + equipamento) - (((pacote + procedimentos + taxa + equipamento) * percentual) + custo))
      // console.log('valorTotaldaContaAtualziadoComDesconto --> ', resultadoTotal)
      return resultadoTotal;
    },
    abstract() {
      const groupedTreatments = this.evolucoes
        .filter(item => item.ativo === true)
        .reduce((acc, row) => {
          const tipoItemNome = this.tipoitem.find(tipo => tipo.id === row.tipo_item)?.nome || 'Desconhecido';
          const procedimento = row.procedimento ? row.procedimento.procedimento : 'null';
          const nomecomercial = row.procedimento ? row.procedimento.nomecomercial : 'null';
          const frequencia = row.frequencia ? row.frequencia.nome : 'null';
          const codigo = row.procedimento ? row.procedimento.codigo : 'null';
          const cobranca = row.cobranca ? 'SIM' : 'NÃO';
          const valor_cobranca = row.valor_cobranca || 0;
          const assinaturaValida = row.assinatura === null ? 0 : row.quantidade;
          const orcamento = row.aditivo ? 'Aditivo' : "Orçamento";
          const nr_aditivo = row.nr_aditivo ? row.nr_aditivo : '';
          const especial = row.procedimento.especial ? row.procedimento.especial : ''
          const desconto = row.desconto ? row.desconto : ''
          const acrescimo = row.acrescimo ? row.acrescimo : ''
          const internacao = row.procedimento.especial ? row.quantidade : 0

          const groupKey = `${procedimento}-${nomecomercial}-${codigo}-${frequencia}-${cobranca}-${valor_cobranca}-${orcamento}-${especial} - ${tipoItemNome} - ${nr_aditivo} - ${desconto} - ${acrescimo} - ${internacao}`;

          if (acc[groupKey]) {
            acc[groupKey].count += 1;
            acc[groupKey].validSignaturesCount += assinaturaValida;
          } else {
            acc[groupKey] = {
              count: row.quantidade,
              validSignaturesCount: assinaturaValida,
              procedimento,
              nomecomercial,
              internacao,
              codigo,
              frequencia,
              especial,
              cobranca,
              orcamento,
              tipoItemNome,
              nr_aditivo,
              unit_price: row.valor_cobranca,
              desconto,
              acrescimo,
              addition: row.acrescimo,
              discount: row.desconto,
              unit_price_formatted: numberToCurrency(row.valor_cobranca || 0),
              addition_formatted: numberToCurrency(row.acrescimo || 0),
              discount_formatted: numberToCurrency(row.desconto || 0),
              reference: row
            };
          }
          return acc;
        }, {});

      return Object.entries(groupedTreatments).map(([, data]) => data);
    },
    canCreateTreatments() {
      const { procedimento, frequencia } = this.treatmentFrequency

      return this.treatmentsToCreate > 0 && this.treatmentsToCreate < 101 && procedimento && frequencia
    },
    pre_loading() {
      return this.pacientes.length > 0 && this.modalidades.length > 0 && this.operadoras.length > 0
    },
    uniqueAditivos() {
      const filteredMatMed = this.matmed.filter(matmed => matmed.ativo === true && matmed.nr_aditivo != null);
      const filteredTaxas = this.taxas.filter(taxa => taxa.ativo === true && taxa.nr_aditivo != null);
      const filteredEquipamentos = this.equipamentos.filter(equipamento => equipamento.ativo === true && equipamento.nr_aditivo != null);
      const filteredEvolucoes = this.evolucoes.filter(evolucao => evolucao.ativo === true && evolucao.nr_aditivo != null);
      const aditivos = [...filteredTaxas, ...filteredEquipamentos, ...filteredEvolucoes, ...filteredMatMed]
        .map(item => item.nr_aditivo)
        .filter((value, index, self) => value != null && self.indexOf(value) === index);
      aditivos.sort((a, b) => a - b);
      // console.log(aditivos)
      return aditivos.length > 0 ? aditivos : null;
    },
    calculatedDays() {
      if (this.selectedRow.data_inicio && this.selectedRow.data_fim) {
        const startDate = new Date(this.selectedRow.data_inicio);
        const endDate = new Date(this.selectedRow.data_fim);
        const timeDiff = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Inclui o dia final no cálculo
        return diffDays;
      }
      return 0; // Retorna 0 se as datas não estiverem definidas
    },
  },
  filters: {
    numberToCurrency: v => numberToCurrency(v || 0)
  },
  watch: {
    'treatmentFrequency.frequencia': {
      deep: true,
      handler() {
        this.calculateSuggestedSessions();
      }
    },
    'selectedRow.data_inicio': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateSuggestedSessions();
      }
    },
    'selectedRow.data_fim': function (newVal, oldVal) {
      if (newVal !== oldVal) {
        this.calculateSuggestedSessions();
      }
    },
    'selectedRow.ativo': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleStatusChange();
      }
    },
    'selectedRow.motivoreprovacao': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleStatusChange();
      }
    },
    'selectedRow.reprovado': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleStatusOrcamentoReprovado()
      }
    },
    'selectedRow.suspender_atendimento': function (newValue, oldValue) {
      if (newValue !== oldValue) {
        this.handleStatusAtendimentoSuspenso();
      }
    },
  },
};
</script>

<style scoped>
div.pre-loading {
  padding: 5rem 0;
  text-align: center;
}

.com-evolucoes {
  color: green;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}

.sem-evolucoes {
  color: red;
  text-align: center;
  /* Outras propriedades de estilo se necessário */
}

.bordered-image {
  border: 2px solid #000;
}

.clickable-cursor {
  cursor: pointer;
}

.container {
  display: flex;
  width: 100%;
  gap: 0.2rem;
  flex-direction: column;
  background-color: white;
}

.text-green {
  color: green;
}

.text-red {
  color: red;
}

.sticky-header {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 10;
  padding: 16px;
  border-bottom: 1px solid #ccc; /* Adiciona uma linha para separação visual */
}

</style>
