<template>
  <div>
    <div>
      <v-row dense>
        <!-- {{selectedRows}} -->
        <v-col cols="2">
          <v-text-field v-model="currentItem.data_inicio" name="data_inicio" required outlined type="date"
            :rules="[(e) => e !== undefined || 'Obrigatório']" clearable>
            <template v-slot:label>
              <span>Data de início do orçamento<span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field v-model="currentItem.data_fim" name="data_fim" required outlined type="date"
            :rules="[(e) => e !== undefined || 'Obrigatório']" clearable>
            <template v-slot:label>
              <span>Data término do orçamento <span style="color: red;">*</span></span>
            </template>
          </v-text-field>
        </v-col>
        <v-col :cols="2">
          <v-text-field  outlined disabled label="QTD Dias Orçados" :value="calculatedDays" ></v-text-field>
        </v-col>
      </v-row>
    </div>
    <v-data-table show-expand :items="selectedRowsDetails" :headers="headers" :loading="isLoading('get:budget-details')">
      <template v-slot:expanded-item="{ item }">
        <td :colspan="headers.length + 1" class="py-4 grey lighten-2">
          <h4 style="margin-bottom: 1rem;">
            Procedimentos
          </h4>
          <v-data-table hide-default-footer :headers="headersProcedures" :items="item.maxProcedures" item-key="index">
            <template v-slot:[`item.amountProceduresToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountProceduresToCreate" dense outlined hide-details v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>
          </v-data-table>


          <h4 style="margin: 1rem 0;">
            Taxas
          </h4>
          <v-data-table hide-default-footer :headers="headersTaxes" :items="item.maxTaxes" item-key="index">
            <template v-slot:[`item.amountTaxesToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountTaxesToCreate" dense outlined hide-details v-bind="attrs"
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.taxa.preco`]="{ item }">
              {{
                formatCurrencyZero(item.taxa.preco)
              }}
            </template>
            <template v-slot:[`item.desconto_aplicado_taxa`]="{ item }">
              <v-text-field   v-model.lazy="item.desconto_aplicado_taxa"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.acrescimo_aplicado_taxa > 0"
              ></v-text-field>
            </template>

            <template v-slot:[`item.acrescimo_aplicado_taxa`]="{ item }">
              <v-text-field   v-model.lazy="item.acrescimo_aplicado_taxa"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.desconto_aplicado_taxa > 0"
              ></v-text-field>
            </template>
          </v-data-table>
          <h4 style="margin: 1rem 0;">
            Equiapmentos
          </h4>
          <v-data-table hide-default-footer :headers="headersEquipamentos" :items="item.maxEquipamentos" item-key="index">
            <template v-slot:[`item.amountEquipamentosToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountEquipamentosToCreate" dense outlined hide-details v-bind="attrs" 
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>

            <template v-slot:[`item.equiapmentos.preco`]="{ item }">
              {{
                formatCurrencyZero(item.equiapmentos.preco)
              }}
            </template>
            <template v-slot:[`item.desconto_aplicado`]="{ item }">
              <v-text-field   v-model.lazy="item.desconto_aplicado"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.acrescimo_aplicado > 0"
              ></v-text-field>
            </template>
            <template v-slot:[`item.acrescimo_aplicado`]="{ item }">
              <v-text-field   v-model.lazy="item.acrescimo_aplicado"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.desconto_aplicado > 0"
              ></v-text-field>
            </template>
          </v-data-table>
          <h4 style="margin: 1rem 0;">
            Material / Medicamento / Dieta
          </h4>
          <v-data-table hide-default-footer :headers="headersMatMed" :items="item.maxMatmed" item-key="index">
            <template v-slot:[`item.amountMatmedToCreate`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field v-model.lazy="item.amountMatmedToCreate" dense outlined hide-details v-bind="attrs" 
                    v-on="on"></v-text-field>
                </template>
                <span>Pode deixar o campo vazio que não será prorrogado</span>
              </v-tooltip>
            </template>
            <template v-slot:[`item.matmed_valor_cobranca_matmed_base`]="{ item }">
              {{ formatCurrencyZero(item.matmed_valor_cobranca_matmed_base) }}
            </template>

            <template v-slot:[`item.matmed_valor_cobranca_matmed`]="{ item }">
              {{ formatCurrencyZero(item.matmed_valor_cobranca_matmed) }}
            </template>

            <template v-slot:[`item.matmed_valor_cobranca_final_matmed`]="{ item }">
              {{ formatCurrencyZero(item.matmed_valor_cobranca_final_matmed) }}
            </template>


            <!-- <template v-slot:[`item.equiapmentos.preco`]="{ item }">
              {{
                formatCurrencyZero(item.equiapmentos.preco)
              }}
            </template> -->
            <template v-slot:[`item.desconto_aplicado_matmed`]="{ item }">
              <v-text-field   v-model.lazy="item.desconto_aplicado_matmed"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.acrescimo_aplicado_matmed > 0"
              ></v-text-field>
            </template>
            <template v-slot:[`item.acrescimo_aplicado_matmed`]="{ item }">
              <v-text-field   v-model.lazy="item.acrescimo_aplicado_matmed"  dense  outlined  hide-details  type="number"  min="0"  max="100" :disabled="item.desconto_aplicado_matmed > 0"
              ></v-text-field>
            </template>
          </v-data-table>
        </td>
      </template>
    </v-data-table>

    <v-form @submit.prevent="onBeforeSubmit" ref="formProrrogar">
      <v-row>
        <v-col class="d-flex align-start">
          <v-btn-toggle v-model="currentItem.ativo" mandatory>
            <v-btn :value="false" :class="{ purple: !currentItem.ativo, 'white--text': !currentItem.ativo }">
              Em análise
            </v-btn>
            <v-btn :value="true" :class="{ green: currentItem.ativo, 'white--text': currentItem.ativo }">
              Aprovado
            </v-btn>
          </v-btn-toggle>
        </v-col>

      </v-row>
      <v-row class="justify-end mt-2">
        <v-col class="text-end">
          <v-tooltip top :disabled="isFormValid">
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                <ConfirmButton :color="isFormValid ? 'success' : 'grey'" :loading=loading :disabled="!isFormValid">
                  Prorrogar </ConfirmButton>
              </span>
            </template>
            <span>Preencha todos os campos obrigatórios (*) para habilitar o botão.</span>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import UtilsFunc from '../../../service/utilsFunc';
import api from "../../../http";
import ConfirmButton from '../../ConfirmButton.vue'
export default {
  name: 'ExtendBudgetForm',
  props: {
    onSubmit: Function,
    selectedRows: Array,
    modalities: Array,
    loading: Boolean
  },
  components: {
    ConfirmButton
  },
  data: () => UtilsFunc.withCRUDUtils({
    currentItem: {},
    selectedRowsDetails: [],
    desconto: [],
    taxa: [],
    matmeds:[],
    headers: [
      { text: 'Nrº Orçamento Principal', value: 'id' },
      { text: 'Paciente', value: 'paciente' },
      { text: 'Procedimentos', value: 'procedimentos' },
      // { text: 'Taxas', value: 'taxas' },
      // { text: 'Equipamentos', value: 'equipamentos' },
    ],
    headersProcedures: [
      { text: 'Id', value: 'id',  align: 'center' },
      { text: 'Procedimentos (Puxa do Orçamento Selecioando)', value: 'procedimento.procedimento',  align: 'center'},
      { text: 'Frequência', value: 'frequencia.nome',  align: 'center' },
      { text: 'Quantidade', value: 'amountProceduresToCreate', width: 250 },
    ],
    headersTaxes: [
      { text: 'Taxas (Puxa do Orçamento Selecioando)', value: 'taxa.taxa.nome',  align: 'center' },
      { text: 'Acréscimo (%)', value: 'acrescimo_aplicado_taxa', align: 'center' },
      { text: 'Desconto (%)', value: 'desconto_aplicado_taxa', align: 'center' },
      { text: 'Quantidade', value: 'amountTaxesToCreate', width: 250 },
    ],
    headersEquipamentos: [
      { text: 'Equipamentos (Puxa do Prontuário)', value: 'nome_equipamento.nome',  align: 'center' },
      { text: 'Acrescimo (%)', value: 'acrescimo_aplicado', align: 'center' },
      { text: 'Desconto (%)', value: 'desconto_aplicado', align: 'center' },
      { text: 'Quantidade Diárias', value: 'amountEquipamentosToCreate', width: 250 },
    ],
    headersMatMed: [
      { text: 'Tipo', value: 'matmed_tipo', align: 'center' },
      { text: 'Material / Medicamento / Dieta (Puxa do Orçamento)', value: 'matmed.nome',  align: 'center' },
      { text: 'Frequência', value: 'matmed_frequencia', align: 'center' },
      { text: 'Código', value: 'matmed_codigo_matmed', align: 'center' },
      { text: 'Acrescimo (%)', value: 'acrescimo_aplicado_matmed', align: 'center' },
      { text: 'Desconto (%)', value: 'desconto_aplicado_matmed', align: 'center' },
      { text: 'Quantidade ', value: 'amountMatmedToCreate', align: 'center' },
      { text: 'Valor Unitário Base', value: 'matmed_valor_cobranca_matmed_base', align: 'center' },
      { text: 'Valor Unitário Atualizado', value: 'matmed_valor_cobranca_matmed', align: 'center' },
      { text: 'Valor Total', value: 'matmed_valor_cobranca_final_matmed', align: 'center' },
    ],
    modalidades: [],
    regraOperadora: [],
    tabelaPropria: [],
  }),
  computed: {
    extendBudgetDiffDays() {
      const { data_fim, data_inicio } = this.currentItem
      if (!data_fim || !data_inicio) return 0
      const dateToMiliseconds = (date) => new Date(date).getTime()
      const difference = dateToMiliseconds(this.currentItem.data_fim) - dateToMiliseconds(this.currentItem.data_inicio)

      return Math.ceil(difference / 1000 / 3600 / 24) + 1
    },
    isFormValid() {
      // Verifica se todos os campos obrigatórios estão preenchidos para poder habilitar o botão de Salvar
      return !!(this.currentItem.data_inicio && this.currentItem.data_fim);
    },
    requiredAsterisk() {
      return '<span style="color: red;">*</span>';
    },
     calculatedDays() {
      if (this.currentItem.data_inicio && this.currentItem.data_fim) {
        const startDate = new Date(this.currentItem.data_inicio);
        const endDate = new Date(this.currentItem.data_fim);
        const timeDiff = Math.abs(endDate - startDate);
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24)) + 1; // Inclui o dia final no cálculo
        return diffDays;
      }
      return 0; // Retorna 0 se as datas não estiverem definidas
    },
  },
  
  methods: {

    formatCurrencyZero(value) {
      return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(value)
    },
    isSameBudget(index) {
      if (index === 0) return false
      const previousRow = this.tableData[index - 1]
      const currentRow = this.tableData[index]

      return previousRow.id === currentRow.id
    },
    async getProcedimentoOperadora() {
      try {
      const { data } = await api.get(`operadoras/operadora-equipamentos/?operadora=${this.selectedRows[0]?.operadora_id}`);
      this.procedimentoOperadora = data.filter(item => item.ativo)
      // console.log('this.procedimentoOperadora -> ', this.procedimentoOperadora)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      }

    },
    async getEquipamento() {
      try {
      const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedRows[0].id}`);
      this.desconto = data
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro em getEquipamento. ${error}`);
      }

    },
    async getTaxas() {
      try {
      const { data } = await api.get(`atendimentos/equipamentos/?orcamento=${this.selectedRows[0].id}`);
      this.taxa = data
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro em getTaxas. ${error}`);
      }
    },
    async getMatmed() {
      try {
      const { data } = await api.get(`atendimentos/matmeds/?orcamento=${this.selectedRows[0].id}`);
      this.matmeds = data
        .filter(item => item.ativo)
        .sort((a, b) => {
          //  Ordenar por matmed.tipo.id (menor ID primeiro)
          if (a.matmed.tipo.id !== b.matmed.tipo.id) {
            return a.matmed.tipo.id - b.matmed.tipo.id;
          }

          //  Ordenar por matmed.nome (ordem alfabética)
          return a.matmed.nome.localeCompare(b.matmed.nome);
        });
      // console.log('this.matmed -> ',this.matmeds)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro em getTaxas. ${error}`);
      }
    },
    async onBeforeSubmit() {
      const isValid = this.$refs.formProrrogar.validate();
      // Verificar se a data de término é menor que a data de início
      if (this.currentItem.data_fim < this.currentItem.data_inicio) {
        this.$toast.error('A data de término não pode ser anterior à data de início.');
        return;
      }
      if (!isValid) return;
      // console.log('this.currentItem --', this.currentItem)
      const fields = { ...this.currentItem }
      // console.log('this.selectedRowsDetails --> ',this.selectedRowsDetails)
      // console.log('fields --> ',fields)
      await this.onSubmit(fields, this.selectedRowsDetails)
    },
    async getBudgetProceduresAndTaxes(id) {
      const { data: budget } = await api.get(`atendimentos/sessao-full/?orcamento=${id}`)
      const { data: taxes } = await api.get(`atendimentos/taxas/?orcamento=${id}`)
      const { data: equipamento } = await api.get(`pacientes/equipamentos/?paciente=${this.selectedRows[0].paciente_id}`)
      const { data: matmedBruto } = await api.get(`pacientes/prescricao/`)
      const pacienteId = this.selectedRows[0].paciente_id;
      const matmed = matmedBruto.filter(item => item.paciente_id == pacienteId);
      // console.log(matmed)
      const equipamentos = equipamento.filter(item => item.ativo && item.nome_equipamento && [1, 2, 4, 13].includes(item.disponibilidade.id) )
      // console.log(equipamentos)
      return {
        budget: budget[0],
        taxes: taxes || [],
        equipamentos: equipamentos || [],
        matmed: matmed || [],
      }

    },

    // Regras para Precificação
    async getRegraOperadora() {
      try {
        const { data } = await api.get(`operadoras/tabela_precificacao/`);
        this.regraOperadora = data.filter(matmed => matmed.ativo === true && matmed.operadora.id === this.selectedRows[0].operadora_id )
        // console.log('Regra Operadora', this.regraOperadora )
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } 
    },
    async getTabelaPropria() {
      try {
        const { data } = await api.get(`matmed/tabela_propria/`);
        this.tabelaPropria = data.filter(matmed => matmed.ativo && matmed.operadora.id === this.selectedRows[0].operadora_id)
        // console.log('Tabela Propria',this.tabelaPropria)
      } catch (error) {
        this.$toast.error(`Desculpe, Aconteceu algum erro. ${error}`);
      } 
    },
    async selecionarProduto(newVal) {
    if (!newVal) return;
    this.newMatMed.preco = null;
    this.newMatMed.codigo = null;
    this.newMatMed.descricao = null;

    try {
      // ✅ 1️⃣ Buscar os dados do produto
      let { data: produtoData } = await api.get(`matmed/matmeds/?matmed_id=${newVal}`);

      if (Array.isArray(produtoData) && produtoData.length > 0) {
        produtoData = produtoData[0];
      } else {
        this.$toast.error('Nenhum produto encontrado para este ID.');
        return;
      }
      // console.log('produtoData-> ', produtoData)

      // ✅ 2️⃣ Armazenar a descrição do produto, se houver
      this.newMatMed.descricao = produtoData.tabela_medicamento?.descricao || produtoData.tabela_simpro?.descricao || "";

      // ✅ 3️⃣ Verificar se o produto está na Tabela Própria
      const tabelaPropriaProduto = this.tabelaPropria.find(item => item.produto.id === newVal);
      if (tabelaPropriaProduto) {
        this.newMatMed.preco = tabelaPropriaProduto.preco.toFixed(2);
        this.newMatMed.codigo = tabelaPropriaProduto.codigo;
        return; // Sai da função pois já encontrou a precificação correta
      }

      const tipoProduto = this.tipoSelecionado;

      if (tipoProduto === 1 || tipoProduto === 3) {
        await this.definirPrecoMedicamento(produtoData);
        // console.log('oi')
      } else if (tipoProduto === 2) {
        await this.definirPrecoMaterial(produtoData);
        // console.log('oi 2')
      }
    } catch (error) {
      console.error('Erro ao buscar o produto:', error);
    }
    },
    async definirPrecoMedicamento(produtoData) {
      const produtoRestritoHospitalar = produtoData.tabela_medicamento?.restrito_hospitalar || false;

      // ✅ Filtrar as regras da operadora e ordenar por prioridade
      let regrasOrdenadas = this.regraOperadora
        .filter(regra => regra.ativo)
        .sort((a, b) => a.ordem - b.ordem);

      if (produtoRestritoHospitalar) {
        regrasOrdenadas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar);
      }

      if (regrasOrdenadas.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada.');
        return;
      }

      let precoBase = null;
      let regraSelecionada = null;

      for (let regra of regrasOrdenadas) {
        precoBase = regra.pfb
          ? parseFloat(produtoData.tabela_medicamento?.preco_fab_unidade)
          : parseFloat(produtoData.tabela_medicamento?.preco_pmc_unidade);

        if (precoBase && precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!precoBase || precoBase <= 0) {
        this.$toast.error('Nenhum preço válido encontrado.');
        return;
      }

      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      let codigo = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_medicamento?.cod_brasindice?.padStart(10, '0');

      this.newMatMed.preco = precoBase.toFixed(2);
      this.newMatMed.codigo = codigo || 0;
    },
    async definirPrecoMaterial(produtoData) {
      if (!produtoData.tabela_simpro) {
        this.$toast.error('Produto não possui preço na tabela SIMPRO.');
        return;
      }

      // ✅ 1️⃣ Verificar se a operadora tem regras ativas para materiais
      let regrasMateriais = this.regraOperadora
        .filter(regra => regra.ativo && regra.tipo_tabela?.id === 1) // Filtra regras para materiais
        .sort((a, b) => a.ordem - b.ordem); // Ordena por prioridade

      if (regrasMateriais.length === 0) {
        this.$toast.error('Nenhuma regra válida encontrada para materiais.');
        return;
      }

      // ✅ 2️⃣ Buscar o preço base do produto
      let precoBase = parseFloat(produtoData.tabela_simpro?.preco_fab_unidade) || 0;

      if (precoBase === 0) {
        this.$toast.error('Nenhum preço válido encontrado para Material.');
        return;
      }

      // ✅ 3️⃣ Percorrer as regras da operadora até encontrar uma válida
      let regraSelecionada = null;

      for (let regra of regrasMateriais) {
        if (precoBase > 0) {
          regraSelecionada = regra;
          break;
        }
      }

      if (!regraSelecionada) {
        this.$toast.error('Nenhuma regra aplicável encontrada para materiais.');
        return;
      }

      // ✅ 4️⃣ Aplicar acréscimo ou desconto, se houver
      if (regraSelecionada.acrescimo) {
        precoBase += (precoBase * regraSelecionada.acrescimo) / 100;
      } else if (regraSelecionada.desconto) {
        precoBase -= (precoBase * regraSelecionada.desconto) / 100;
      }

      // ✅ 5️⃣ Formatar o código do produto garantindo 10 dígitos
      let codigoMaterial = produtoData.tabela_medicamento?.cod_TUSS
        ? produtoData.tabela_medicamento.cod_TUSS
        : produtoData.tabela_simpro?.cod_simpro?.toString().padStart(10, '0');

      // ✅ 6️⃣ Atualizar os valores no formulário
      this.newMatMed.preco = precoBase.toFixed(2);
      this.atualizarCodigo(codigoMaterial); // Garante que sempre terá um código
    },
    async getBudgetDetails() {
      const LOADING_NAME = 'get:budget-details'
      this.setLoading(LOADING_NAME)
      try {
        const selectedRowsIds = this.selectedRows.map(row => row.id)

        const responses = await Promise.all(selectedRowsIds.map(this.getBudgetProceduresAndTaxes))

        const data = responses
        this.currentItem.modalidade = data[0].budget.modalidade

        const excludedProcedureIds = [43, 44, 45, 46];

        this.selectedRowsDetails = data.map(item => {
          const budget = item.budget
          const sortedProcedures = budget.evolucoes
            .filter(procedure => !excludedProcedureIds.includes(procedure.procedimento.procedimento_id)) // Exclusõa dos procedimentos de tec de enfermagem 6h, 12h e 24h
            .sort((a, b) => {
              return b.id - a.id
            })

          const maxProcedures = sortedProcedures.reduce((acc, procedure) => {
            if (acc.some(p => p.procedimento.procedimento_id === procedure.procedimento.procedimento_id)) return acc
            acc.push({
              ...procedure,
              procedimento: {
                ...procedure.procedimento,
                preco: Number(procedure.procedimento.preco)
              }
            })
            return acc
          }, [])
          
          const maxTaxes = item.taxes.reduce((acc, tax) => {
            if (acc.some(t => t.taxa.id === tax.taxa.id)) return acc
            
            acc.push({
              ...tax,
              taxa_ref: tax.taxa.taxa.id,
              valor: tax.valor_cobranca_taxa,
              desconto_aplicado_taxa: tax.desconto_taxa || null, // Garante que o campo existe
              acrescimo_aplicado_taxa: tax.acrescimo_taxa || null, // Garante que o campo existe
              amountTaxesToCreate: 1
            })

            return acc
          }, [])
            // console.log('this.matmeds -> ', this.matmeds)
          const maxMatmed = this.matmeds.reduce((acc, mat) => {
            // if (acc.some(t => t.taxa.id === tax.matmed.id)) return acc
            
            acc.push({
              ...mat,
              matmed_ref: mat.matmed.id,
              valor: mat.valor_cobranca_matmed,
              fator: mat.frequencia.fator,
              desconto_aplicado_matmed: mat.desconto_matmed || null, // Garante que o campo existe
              acrescimo_aplicado_matmed: mat.acrescimo_matmed || null, // Garante que o campo existe
              matmed_tipo: mat.matmed.tipo.tipo || null,
              matmed_codigo_matmed: null,
              matmed_frequencia: mat.frequencia.nome || null, 
              matmed_valor_cobranca_matmed_base: null,
              matmed_valor_cobranca_matmed: null,
              matmed_valor_cobranca_final_matmed: null,
              amountMatmedToCreate:  null
            })

            return acc
          }, [])

          // console.log('item.equipamentos', item.equipamentos)
          const maxEquipamentos = item.equipamentos.map((equi) => {
            // Certifica-se de que nome_equipamento existe antes de acessar .id
            if (!equi.nome_equipamento || !equi.nome_equipamento.id) {
              console.warn('Equipamento sem nome_equipamento ou ID:', equi);
              return {
                ...equi,
                equi_ref: null, // Define como null caso não exista nome_equipamento
                quant: this.calculatedDays,
                equipamento_procedimnto_operadora: null,
                operadora: equi.operadora,
                valor: 0,
                amountEquipamentosToCreate: 1,
                desconto_aplicado: descontoCorrespondente ? descontoCorrespondente.desconto_equipamento : 0, // Adiciona o desconto correto
                acrescimo_aplicado: acrescimoCorrespondente ? acrescimoCorrespondente.acrescimo_equipamento : 0, // Adiciona o desconto correto
              };
            }

            // Filtra o equipamento_procedimnto_operadora com base no nome_equipamento.id
            const equipamentoProcedimentoOperadora = this.procedimentoOperadora.find(
              (operadoraEquip) => operadoraEquip.equipamento.id === equi.nome_equipamento.id
            );
            const descontoCorrespondente = this.desconto.find(
              (desc) =>
                desc.equipamento.equipamento.id === equi.nome_equipamento.id &&
                desc.desconto_equipamento !== 100 &&
                desc.desconto_equipamento !== 0
            );
            const acrescimoCorrespondente = this.desconto.find(
              (desc) =>
                desc.equipamento.equipamento.id === equi.nome_equipamento.id &&
                desc.acrescimo_equipamento !== 100 &&
                desc.acrescimo_equipamento !== 0
            );

            return {
              ...equi,
              equi_ref: equi.nome_equipamento.id,
              quant: this.calculatedDays,
              equipamento_procedimnto_operadora: equipamentoProcedimentoOperadora
                ? equipamentoProcedimentoOperadora.id // Passa o ID encontrado
                : null, // Caso não encontre, define como null
              desconto_aplicado: descontoCorrespondente ? descontoCorrespondente.desconto_equipamento : null, // Adiciona o desconto correto
              acrescimo_aplicado: acrescimoCorrespondente ? acrescimoCorrespondente.acrescimo_equipamento : null, // Adiciona o desconto correto
              pacote_numero: this.selectedRows[0]?.pacote_pacote_id || null,
              operadora: equi.operadora,
              valor: 0,
              amountEquipamentosToCreate: 1,
            };
          });


            // console.log('maxEquipamentos', maxEquipamentos)
          return {
            ...this.selectedRows.find(row => row.id === budget.id),
            ...budget,
            procedimentos: Array.from(new Set(budget.evolucoes
              .map(({ procedimento }) => procedimento.procedimento))).join(', '),
            maxProcedures,
            maxTaxes,
            maxEquipamentos,
            maxMatmed,
          }
        })

      } catch (error) {
        console.error(error)
        this.$toast.error(`Falha ao carregar orçamentos ${error}`)
      } finally {
        this.setLoading(LOADING_NAME, true)
      }
    },

  },
  watch: {
    selectedRowsDetails: {
      handler(newValue) {
        newValue.forEach(item => {
          if (item.acrescimo_aplicado >= 0) {
            item.desconto_aplicado = null; // Limpa desconto se houver acréscimo
          } else if (item.desconto_aplicado >= 0) {
            item.acrescimo_aplicado = null; // Limpa acréscimo se houver desconto
          }
          if (item.acrescimo_aplicado_taxa >= 0) {
            item.desconto_aplicado_taxa = null; // Limpa desconto se houver acréscimo
          } else if (item.desconto_aplicado_taxa >= 0) {
            item.acrescimo_aplicado_taxa = null; // Limpa acréscimo se houver desconto
          }
          if (item.acrescimo_aplicado_matmed >= 0) {
            item.desconto_aplicado_matmed = null; // Limpa desconto se houver acréscimo
          } else if (item.desconto_aplicado_matmed >= 0) {
            item.acrescimo_aplicado_matmed = null; // Limpa acréscimo se houver desconto
          }
          // NOVA REGRA: Recalcular valores unitário e total de matmed
          if (item.maxMatmed && Array.isArray(item.maxMatmed)) {
            item.maxMatmed.forEach(matmed => {
              const quantidade = matmed.amountMatmedToCreate || 0;
              const precoUnitarioBase = parseFloat(matmed.matmed_valor_cobranca_matmed_base || 0);
              let precoFinalUnitario = precoUnitarioBase;

              if (matmed.acrescimo_aplicado_matmed > 0) {
                precoFinalUnitario += (precoUnitarioBase * matmed.acrescimo_aplicado_matmed) / 100;
              } else if (matmed.desconto_aplicado_matmed > 0) {
                precoFinalUnitario -= (precoUnitarioBase * matmed.desconto_aplicado_matmed) / 100;
              }

              // ✅ Atualiza valor unitário com desconto/acréscimo
              matmed.matmed_valor_cobranca_matmed = precoFinalUnitario.toFixed(2);

              // ✅ Atualiza valor total
              matmed.matmed_valor_cobranca_final_matmed = (precoFinalUnitario * quantidade).toFixed(2);
            });
          }

        });
      },
      deep: true
    },
    extendBudgetDiffDays: function () {
      const dias = this.extendBudgetDiffDays;
      this.selectedRowsDetails = this.selectedRowsDetails.map(row => {
        row.maxProcedures = row.maxProcedures.map(procedure => {
          const frequencia = procedure.frequencia || {};
          const fator = frequencia.fator || 1;

          let quantidade = 0;

          if (frequencia.id === 11) {
            // 1x MÊS => Sempre 1
            quantidade = 1;
          } else if (frequencia.id === 10 && dias === 31) {
            // 3x SEMANA com 31 dias => fixo em 2
            quantidade = 2;
          } else {
            quantidade = Math.ceil(dias * fator);
          }

          return {
            ...procedure,
            amountProceduresToCreate: quantidade,
          };
        });

        return { ...row };
      });
    },
    calculatedDays(newDays) {
      if (newDays) {
        this.selectedRowsDetails = this.selectedRowsDetails.map(row => {
          row.maxEquipamentos = row.maxEquipamentos.map(equipamento => ({
            ...equipamento,
            amountEquipamentosToCreate: newDays // Atualiza o valor automaticamente
          }));

          row.maxMatmed = row.maxMatmed.map(matmed => {
            const quantidade = Math.max(1, Math.round(newDays * (matmed.frequencia?.fator || 1)));

            // ✅ 1️⃣ Verifica se existem regras da operadora antes de continuar
            let regrasOrdenadas = this.regraOperadora
              .filter(regra => regra.ativo)
              .sort((a, b) => a.ordem - b.ordem);

            if (regrasOrdenadas.length === 0) {
              this.$toast.error('Nenhuma regra de negócio encontrada para essa operadora. (Material / Medicamento ou Dieta)');
              return matmed;
            }

            // ✅ 2️⃣ Verifica se o MatMed está na Tabela Própria
            const tabelaPropriaProduto = this.tabelaPropria.find(item => item.produto.id === matmed.matmed.id);
              if (tabelaPropriaProduto) {
                const precoBaseMatMed = tabelaPropriaProduto.preco; // valor original
                let precoComDescontoOuAcrescimo = precoBaseMatMed;

                // Se tiver acréscimo/desconto manual no matmed, usa ele
                const acrescimoFinal = matmed.acrescimo_matmed > 0 ? matmed.acrescimo_matmed : null;
                const descontoFinal = matmed.desconto_matmed > 0 ? matmed.desconto_matmed : null;

                if (acrescimoFinal !== null) {
                  precoComDescontoOuAcrescimo = precoBaseMatMed + (precoBaseMatMed * acrescimoFinal) / 100;
                } else if (descontoFinal !== null) {
                  precoComDescontoOuAcrescimo = precoBaseMatMed - (precoBaseMatMed * descontoFinal) / 100;
                }

                const precoFinal = precoComDescontoOuAcrescimo * quantidade;

                return {
                  ...matmed,
                  amountMatmedToCreate: quantidade,
                  matmed_codigo_matmed: tabelaPropriaProduto.codigo,
                  acrescimo_aplicado_matmed: acrescimoFinal,
                  desconto_aplicado_matmed: descontoFinal,
                  matmed_valor_cobranca_matmed_base: precoBaseMatMed.toFixed(2),
                  matmed_valor_cobranca_matmed: precoComDescontoOuAcrescimo.toFixed(2),
                  matmed_valor_cobranca_final_matmed: precoFinal.toFixed(2),
                };
              }
            //  Manipualdo
            if(matmed.matmed.manipulado === true) {      
              // console.log('manipualdo')
             let regrasOrdenadas = this.regraOperadora
                .filter(regra => regra.ativo && regra.manipulado )
                .sort((a, b) => a.ordem - b.ordem);
                regrasOrdenadas = regrasOrdenadas[0]
                // console.log('regrasOrdenadas -> ', regrasOrdenadas)

                if (regrasOrdenadas.length === 0) {
                  this.$toast.error('Nenhuma regra válida encontrada.');
                  return;
                }

                let precoBaseMatMed = null;
                let precoBase = null;
                let regraSelecionada = regrasOrdenadas;
                
                precoBaseMatMed = parseFloat(matmed.matmed.custo)
                   
                if (!precoBaseMatMed || precoBaseMatMed <= 0) {
                  this.$toast.error(`Nenhum preço válido encontrado para ${matmed.matmed.nome}.`);
                  return matmed;
                }

                // ✅ 6️⃣ Aplicar acréscimo ou desconto corretamente
                
                // **Se o matmed tiver um percentual definido, ele deve prevalecer**
                let acrescimoFinal = matmed.acrescimo_matmed > 0 ? matmed.acrescimo_matmed : null;
                // console.log(acrescimoFinal)
                let descontoFinal = matmed.desconto_matmed > 0 ? matmed.desconto_matmed : null;
                // console.log('regraSelecionada',regraSelecionada)
                if (acrescimoFinal === null && descontoFinal === null) {
                  acrescimoFinal = regraSelecionada?.acrescimo ?? null;
                  descontoFinal = regraSelecionada?.desconto ?? null;
                }
                
                // Aplica acréscimo ou desconto ao preço final
                if (acrescimoFinal > 0) {
                  precoBase += (precoBaseMatMed * acrescimoFinal) / 100;
                } else if (descontoFinal > 0) {
                  precoBase -= (precoBaseMatMed * descontoFinal) / 100;
                }
                let precoFinal = precoBase * quantidade;
                return {
                  ...matmed,
                  amountMatmedToCreate: quantidade,
                  matmed_codigo_matmed: matmed.codigo || matmed.matmed.id.toString().padStart(10, '0'),
                  acrescimo_aplicado_matmed: acrescimoFinal,
                  desconto_aplicado_matmed: descontoFinal,
                  matmed_valor_cobranca_matmed_base: precoBaseMatMed.toFixed(2),
                  matmed_valor_cobranca_matmed: precoBase.toFixed(2),
                  matmed_valor_cobranca_final_matmed: precoFinal.toFixed(2),
                };
              }
            // Medicamento e Dieta
            if(!matmed.matmed.manipualdo && matmed.matmed.tipo.id !== 2) {      
              // console.log('medicamento e dieta', matmed.matmed.nome)
              // ✅ 3️⃣ Se não está na Tabela Própria, buscar pela Regra da Operadora
              let produtoRestritoHospitalar = (matmed.matmed.tabela_medicamento?.restrito_hospitalar) || false;
              // console.log(produtoRestritoHospitalar)

              let regrasValidas = [];
              if (produtoRestritoHospitalar) {
                // ✅ 4️⃣1️⃣ Se for Restrito Hospitalar, usa apenas regras compatíveis
                regrasValidas = regrasOrdenadas.filter(regra => regra.restrito_hospitalar );
                // console.log(regrasValidas)
              } else {
                // ✅ 4️⃣2️⃣ Se NÃO for Restrito, exclui regras que são apenas para restritos
                regrasValidas = regrasOrdenadas.filter(regra => !regra.restrito_hospitalar );
              }

              if (regrasValidas.length === 0) {
                this.$toast.error(`Nenhuma regra válida encontrada para ${matmed.matmed.nome}.`);
                return matmed;
              }

              // ✅ 5️⃣ Encontrar a primeira regra válida

                let precoBaseMatMed = null;
                let precoBase = null;
                let regraSelecionada = null;
                for (let regra of regrasValidas) {
                  precoBaseMatMed = regra.pfb
                    ? parseFloat(matmed.matmed.tabela_medicamento?.preco_fab_unidade)
                    : parseFloat(matmed.matmed.tabela_medicamento?.preco_pmc_unidade);

                  if (precoBaseMatMed && precoBaseMatMed > 0) {
                    regraSelecionada = regra;
                    break; // Para na primeira regra válida
                  }
                }

                if (!precoBaseMatMed || precoBaseMatMed <= 0) {
                  this.$toast.error(`Nenhum preço válido encontrado para ${matmed.matmed.nome}.`);
                  return matmed;
                }

                // ✅ 6️⃣ Aplicar acréscimo ou desconto corretamente
                
                // **Se o matmed tiver um percentual definido, ele deve prevalecer**
                let acrescimoFinal = matmed.acrescimo_matmed > 0 ? matmed.acrescimo_matmed : null;
                // console.log(acrescimoFinal)
                let descontoFinal = matmed.desconto_matmed > 0 ? matmed.desconto_matmed : null;
                // console.log('regraSelecionada',regraSelecionada)
                if (acrescimoFinal === null && descontoFinal === null) {
                  acrescimoFinal = regraSelecionada?.acrescimo ?? null;
                  descontoFinal = regraSelecionada?.desconto ?? null;
                }
                
                // Aplica acréscimo ou desconto ao preço final
                if (acrescimoFinal > 0) {
                  precoBase += (precoBaseMatMed * acrescimoFinal) / 100;
                } else if (descontoFinal > 0) {
                  precoBase -= (precoBaseMatMed * descontoFinal) / 100;
                }
                let precoFinal = precoBase * quantidade;
                return {
                  ...matmed,
                  amountMatmedToCreate: quantidade,
                  matmed_codigo_matmed: matmed.matmed.tabela_medicamento?.cod_TUSS || matmed.matmed.tabela_medicamento?.cod_brasindice?.padStart(10, '0'),
                  acrescimo_aplicado_matmed: acrescimoFinal,
                  desconto_aplicado_matmed: descontoFinal,
                  matmed_valor_cobranca_matmed_base: precoBaseMatMed.toFixed(2),
                  matmed_valor_cobranca_matmed: precoBase.toFixed(2),
                  matmed_valor_cobranca_final_matmed: precoFinal.toFixed(2),
                };
              } 

            // Material 
            if (matmed.matmed.tipo.id === 2) {
                //  console.log('material')     
                let precoBaseMatMed = null;
                let precoBase = null;
                let regraSelecionada = regrasOrdenadas.filter(item => item.tipo_tabela.id === 1);
                for (let regra of regraSelecionada) {
                  precoBaseMatMed =  parseFloat(matmed.matmed.tabela_simpro?.preco_fab_unidade)
                    

                  if (precoBaseMatMed && precoBaseMatMed > 0) {
                    regraSelecionada = regra;
                    break; // Para na primeira regra válida
                  }
                }

                if (!precoBaseMatMed || precoBaseMatMed <= 0) {
                  this.$toast.error(`Nenhum preço válido encontrado para ${matmed.matmed.nome}.`);
                  return matmed;
                }

                // ✅ 6️⃣ Aplicar acréscimo ou desconto corretamente
                
                // **Se o matmed tiver um percentual definido, ele deve prevalecer**
                let acrescimoFinal = matmed.acrescimo_matmed > 0 ? matmed.acrescimo_matmed : null;
                // console.log(acrescimoFinal)
                let descontoFinal = matmed.desconto_matmed > 0 ? matmed.desconto_matmed : null;
                // console.log('regraSelecionada',regraSelecionada)
                if (acrescimoFinal === null && descontoFinal === null) {
                  acrescimoFinal = regraSelecionada?.acrescimo ?? null;
                  descontoFinal = regraSelecionada?.desconto ?? null;
                }
                
                // Aplica acréscimo ou desconto ao preço final
                if (acrescimoFinal > 0) {
                  precoBase += (precoBaseMatMed * acrescimoFinal) / 100;
                } else if (descontoFinal > 0) {
                  precoBase -= (precoBaseMatMed* descontoFinal) / 100;
                }
                let precoFinal = precoBase * quantidade;
                return {
                  ...matmed,
                  amountMatmedToCreate: quantidade,
                  matmed_codigo_matmed: matmed.matmed.tabela_simpro?.cod_TUSS || matmed.matmed.tabela_simpro?.cod_brasindice?.padStart(10, '0'),
                  acrescimo_aplicado_matmed: acrescimoFinal,
                  desconto_aplicado_matmed: descontoFinal,
                  matmed_valor_cobranca_matmed_base: precoBaseMatMed.toFixed(2),
                  matmed_valor_cobranca_matmed: precoBase.toFixed(2),
                  matmed_valor_cobranca_final_matmed: precoFinal.toFixed(2),
                };
              
              }

          });

          return { ...row };
        });

        this.$emit("update-days", newDays);
      }
    }
  },
  mounted() {
    this.getBudgetDetails()
    this.getProcedimentoOperadora()
    this.getEquipamento()
    this.getTaxas()
    this.getMatmed()
    this.getRegraOperadora()
    this.getTabelaPropria()
  }
}
</script>
